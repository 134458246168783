import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FilledInput,
  Icon,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useLocation } from "react-router-dom";
import ViewTitle from "../../common/view-title";
import AuthContext from "../../context";
import { get } from "../../utils/api-services";
import helpers from "../../utils/helpers";
import DateRange from "../../common/date-range";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import FilterInput from "../components/FilterInput";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingBottom: 20,
  },
  tableContainer: {
    width: "100%",
    marginTop: 20,
  },
  clear: {
    backgroundColor: "#A4A4A4",
    borderColor: "#A4A4A4",
    "&:hover": {
      backgroundColor: "#A4A4A4",
      borderColor: "#A4A4A4",
      boxShadow: "none",
    },
  },
  search: {
    backgroundColor: "#0d6efd",
    borderColor: "#0d6efd",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#0d6efd",
      borderColor: "#0d6efd",
      boxShadow: "none",
    },
  },
  buttonsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  buttonRequestContainer: {
    width: "100%",
    display: "flex",
    paddingTop: 20,
    paddingBottom: 20,
  },
  acceptButton: {
    backgroundColor: "#198754",
    borderColor: "#198754",
    width: "50%",
    "&:hover": {
      backgroundColor: "#198754",
      borderColor: "#198754",
      boxShadow: "none",
    },
  },
  deleteReportButton: {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    marginLeft: 10,
    width: "50%",
    "&:hover": {
      backgroundColor: "#c82333",
      borderColor: "#bd2130",
      boxShadow: "none",
    },
  },
  deleteReportButton2: {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    width: "20%",
    color: "white",
    "&:hover": {
      backgroundColor: "#c82333",
      borderColor: "#bd2130",
      boxShadow: "none",
    },
  },
  requiredValue: {
    fontWeight: "600",
    color: "rgba(255,0,0,1)",
  },
  divider: {
    marginBottom: 10,
  },
  totalBox: {
    justifyContent: "space-around",
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    marginBottom: 15,
  },
}));

const Sales = () => {
  const { showToast, signOut, showLoader } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const cleanCategoryRef = useRef(null);
  const classes = useStyles();
  const { pathname } = useLocation();
  const [state, setState] = useState({
    loading: true,
    savingReports: false,
    dialogSave: false,
    data: [],
    pageSize: 50,
    filter: {
      invoiceDateRange: `${dayjs()
        .subtract(7, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
      product: "",
      legacy_code: "",
      invoice_number: "",
    },
    rangeModal: {
      open: false,
      range: [
        {
          startDate: new Date(dayjs().subtract(2, "day").toDate()),
          endDate: new Date(),
          key: "invoice",
        },
      ],
    },
    requestRangeModal: {
      open: false,
      dateRange: `${dayjs()
        .subtract(4, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
      startDate: `${dayjs().subtract(4, "day").format("YYYY-MM-DD")}`,
      endDate: `${dayjs().format("YYYY-MM-DD")}`,
      range: [
        {
          startDate: new Date(dayjs().subtract(4, "day").toDate()),
          endDate: new Date(dayjs().toDate()),
          key: "main",
        },
      ],
    },
    fileData: [],
    avoided: [],
    permissions: {},
  });

  useEffect(() => {
    window.document.title = "Komet Sales";

    validatePermissions();
  }, []);

  const validatePermissions = () => {
    const permissions = helpers.getPermissions(pathname);
    setState((prev) => ({ ...prev, permissions }));

    permissions.read_attr
      ? makeRequest()
      : setState((prev) => ({ ...prev, loading: false }));
  };

  const makeRequest = async () => {
    showLoader(true);
    const response = await get("/kometSales/index", state.filter);
    if (response.status === 200) {
      setState((prev) => ({ ...prev, loading: false, data: response.data }));
    } else {
      console.log("[SalesReport] makeRequest error", response);
      helpers.failRequest(response, showToast, signOut);
    }
    showLoader(false);
  };

  const getDate = (params) => {
    const formattedDate = dayjs(params.value);

    if (!formattedDate.isValid()) {
      return "N/A";
    }

    return formattedDate.format("MM/DD/YYYY");
  };

  const getMiamiTimeZoneDate = (params) => {
    return dayjs(params.value).tz("America/New_York").format("MM/DD/YYYY");
  };

  const columns = [
    { field: "idkomet_sale", headerName: "Id", flex: 0.8, minWidth: 100 },
    {
      field: "report_date",
      headerName: "Report Date",
      flex: 0.8,
      minWidth: 150,
      valueGetter: getMiamiTimeZoneDate,
    },
    { field: "detail_id", headerName: "Detail ID", flex: 0.8, minWidth: 150 },
    { field: "category", headerName: "Category", flex: 0.8, minWidth: 150 },
    { field: "legacy_code", headerName: "Legacy Code", flex: 1, minWidth: 150 },
    {
      field: "invoice_number",
      headerName: "Invoice Number",
      flex: 0.8,
      minWidth: 160,
    },
    {
      field: "prebook_number",
      headerName: "Prebook Number",
      flex: 0.8,
      minWidth: 170,
    },
    { field: "product", headerName: "Product", flex: 0.8, minWidth: 290 },
    { field: "variety", headerName: "Variety", flex: 0.8, minWidth: 290 },
    {
      field: "truck_date",
      headerName: "Truck Date",
      flex: 0.8,
      minWidth: 150,
      valueGetter: getDate,
    },
    {
      field: "invoice_date",
      headerName: "Invoice Date",
      flex: 0.8,
      minWidth: 150,
      valueGetter: getDate,
    },
    {
      field: "prebook_boxes",
      headerName: "Prebook Boxes",
      flex: 0.8,
      minWidth: 150,
    },
    { field: "po_boxes", headerName: "PO Boxes", flex: 0.8, minWidth: 150 },
    {
      field: "invoice_boxes",
      headerName: "Invoice Boxes",
      flex: 0.8,
      minWidth: 150,
    },
    { field: "po_number", headerName: "PO Number", flex: 0.8, minWidth: 150 },
    {
      field: "farm_ship_date",
      headerName: "Farm Ship Date",
      flex: 0.8,
      minWidth: 150,
      valueGetter: getDate,
    },
    { field: "awb", headerName: "AWB", flex: 0.8, minWidth: 150 },
    {
      field: "truck_month",
      headerName: "Truck Month",
      flex: 0.8,
      minWidth: 150,
    },
    { field: "farm_name", headerName: "Farm Name", flex: 0.8, minWidth: 210 },
    { field: "farm_code", headerName: "Farm Code", flex: 0.8, minWidth: 150 },
    { field: "ship_via", headerName: "Ship Via", flex: 0.8, minWidth: 150 },
    { field: "location", headerName: "Location", flex: 0.8, minWidth: 150 },
    { field: "customer", headerName: "Customer", flex: 0.8, minWidth: 200 },
    { field: "city", headerName: "City", flex: 0.8, minWidth: 120 },
    { field: "state", headerName: "State", flex: 0.8, minWidth: 100 },
    { field: "zip_code", headerName: "Zip Code", flex: 0.8, minWidth: 150 },
    {
      field: "customer_type",
      headerName: "Customer Type",
      flex: 0.8,
      minWidth: 160,
    },
    {
      field: "sales_person",
      headerName: "Sales Person",
      flex: 0.8,
      minWidth: 150,
    },
    { field: "units_box", headerName: "Units/Box", flex: 0.8, minWidth: 150 },
    {
      field: "units_bunch_final",
      headerName: "Units/Bunch",
      flex: 0.8,
      minWidth: 150,
    },
    { field: "box_type", headerName: "Box Type", flex: 0.8, minWidth: 150 },
    {
      field: "total_units",
      headerName: "Total Units",
      flex: 0.8,
      minWidth: 150,
    },
    { field: "unit_type", headerName: "Unit Type", flex: 0.8, minWidth: 150 },
    {
      field: "bunches_box",
      headerName: "Bunches/Box",
      flex: 0.8,
      minWidth: 150,
    },
    { field: "color", headerName: "Color", flex: 0.8, minWidth: 150 },
    { field: "grade", headerName: "Grade", flex: 0.8, minWidth: 150 },
    { field: "country", headerName: "Country", flex: 0.8, minWidth: 150 },
    { field: "sales_type", headerName: "Sales Type", flex: 0.8, minWidth: 150 },
    {
      field: "sales_unit_price",
      headerName: "Sales Unit Price",
      flex: 0.8,
      minWidth: 170,
    },
    {
      field: "total_price",
      headerName: "Total Price",
      flex: 0.8,
      minWidth: 150,
    },
    { field: "unit_cost", headerName: "Unit Cost", flex: 0.8, minWidth: 150 },
    { field: "total_cost", headerName: "Total Cost", flex: 0.8, minWidth: 150 },
    {
      field: "handling_cost_unit",
      headerName: "Handling Cost/Unit",
      flex: 0.8,
      minWidth: 180,
    },
    {
      field: "awb_freight_cost_unit",
      headerName: "AWB Freight  Cost/Unit",
      flex: 0.8,
      minWidth: 190,
    },
    {
      field: "duties_cost_unit",
      headerName: "Duties Cost/Unit",
      flex: 0.8,
      minWidth: 170,
    },
    {
      field: "landed_cost_unit",
      headerName: "Landed Cost/Unit",
      flex: 0.8,
      minWidth: 170,
    },
    { field: "gpm", headerName: "GPM", flex: 0.8, minWidth: 100 },
    {
      field: "total_handling",
      headerName: "Total Handling",
      flex: 0.8,
      minWidth: 150,
    },
    {
      field: "total_awb_freight",
      headerName: "Total AWB Freight",
      flex: 0.8,
      minWidth: 170,
    },
    {
      field: "total_landed_cost",
      headerName: "Total Landed Cost",
      flex: 0.8,
      minWidth: 170,
    },
    {
      field: "customer_code",
      headerName: "Customer Code",
      flex: 0.8,
      minWidth: 170,
    },
    {
      field: "carrier_code",
      headerName: "Carrier Code",
      flex: 0.8,
      minWidth: 150,
    },
    { field: "aging", headerName: "Aging", flex: 0.8, minWidth: 100 },
  ];

  const handleDateChange = (item, key) => {
    const { startDate, endDate } = item[key];
    const dateRange = `${dayjs(startDate.toISOString()).format(
      "MM/DD/YYYY"
    )} - ${dayjs(endDate.toISOString()).format("MM/DD/YYYY")}`;
    setState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        [`${key}DateRange`]: dateRange,
        [`${key}_start_date`]: dayjs(startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        [`${key}_end_date`]: dayjs(endDate.toISOString()).format("YYYY-MM-DD"),
      },
      rangeModal: {
        ...prev.rangeModal,
        [key === "invoice" ? "range" : "reportRange"]: [item[key]],
      },
    }));
  };

  const handleModal = (key) => {
    setState((prev) => ({
      ...prev,
      rangeModal: {
        ...prev.rangeModal,
        open: !prev.rangeModal.open,
        modalKey: key,
      },
    }));
  };
  const handleAutocomplete = (key, value) => {
    setState((prev) => ({
      ...prev,
      filter: { ...prev.filter, [key]: value },
    }));
  };

  const handleClean = () => {
    setState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        product: "",
        legacy_code: "",
        invoice_number: "",
        invoiceDateRange: "",
        category: "",
        invoice_start_date: "",
        invoice_end_date: "",
      },
    }));
    if (cleanCategoryRef.current) {
      cleanCategoryRef.current();
    }
  };

  const search = async () => {
    const { filter } = state;

    const hasInvalidCharacter = Object.values(filter).some((value) =>
      value.includes("#")
    );
    if (hasInvalidCharacter) {
      showToast("Filters cannot contain the '#' character", "error");
      return;
    }

    const trimmedFilter = {
      ...filter,
      legacy_code: filter.legacy_code.trim().replace(/\s+/g, " "),
      product: filter.product.trim().replace(/\s+/g, " "),
      invoice_number: filter.invoice_number.trim().replace(/\s+/g, " "),
    };

    showLoader(true);
    const response = await get("/kometSales/search", trimmedFilter);
    if (response.status === 200) {
      setState((prev) => ({ ...prev, data: response.data }));
      if (!response?.data[0])
        showToast("There are no data with these filters", "warning");
    } else {
      console.log("[Sales] search error", response);
      helpers.failRequest(response, showToast, signOut);
    }
    showLoader(false);
  };

  const handleRequestModal = () => {
    setState((prev) => {
      const isClosing = prev.requestRangeModal.open;
      return {
        ...prev,
        requestRangeModal: {
          ...prev.requestRangeModal,
          open: !prev.requestRangeModal.open,
          ...(isClosing && {
            range: [
              {
                startDate: new Date(dayjs().subtract(4, "day").toDate()),
                endDate: new Date(dayjs().toDate()),
                key: "main",
              },
            ],
            startDate: `${dayjs().subtract(4, "day").format("YYYY-MM-DD")}`,
            endDate: `${dayjs().format("YYYY-MM-DD")}`,
            minDate: null,
            maxDate: null,
          }),
        },
      };
    });
  };

  const handleRequestDateChange = (item) => {
    const { startDate, endDate } = item.main;
    const minDate = dayjs(startDate).subtract(365, "day").toDate();
    const maxDate = dayjs(startDate).add(365, "day").toDate();
    const dateRange = `${dayjs(startDate.toISOString()).format(
      "MM/DD/YYYY"
    )} - ${dayjs(endDate.toISOString()).format("MM/DD/YYYY")}`;
    setState((prev) => ({
      ...prev,
      requestRangeModal: {
        open: true,
        range: [item.main],
        dateRange: dateRange,
        startDate: `${dayjs(startDate.toISOString()).format("YYYY-MM-DD")}`,
        endDate: `${dayjs(endDate.toISOString()).format("YYYY-MM-DD")}`,
        minDate,
        maxDate,
      },
    }));
  };

  const addRequest = async () => {
    try {
      showToast("Your sales report is being processed.", "warning");
      setState((prev) => ({
        ...prev,
        savingReports: true,
      }));
      const saveRequest = await get("/kometSales/store", {
        startDate: state.requestRangeModal.startDate,
        endDate: state.requestRangeModal.endDate,
      });

      if (
        saveRequest.status === 200 &&
        Array.isArray(saveRequest.data) &&
        saveRequest.data.length > 0
      ) {
        setState((prev) => ({
          ...prev,
          data: saveRequest.data,
          savingReports: false,
        }));
        showToast("Your sales report is ready", "success");
      } else if (saveRequest.status === 205) {
        setState((prev) => ({
          ...prev,
          savingReports: false,
        }));
        showToast("No data found", "error");
      } else {
        setState((prev) => ({
          ...prev,
          savingReports: false,
        }));
        showToast(saveRequest.message, "error");
      }
    } catch (error) {
      showToast(error, "warning");
    }
  };

  const destroyReport = async () => {
    const saveRequest = await get("/kometSales/destroy");

    if (saveRequest.status === 200) {
      setState((prev) => ({
        ...prev,
        data: saveRequest.data,
      }));
    } else {
      console.log("[Request] Request error", saveRequest);
    }

    showToast("Reports deleted", "error");
    setState((prev) => ({ ...prev, dialogSave: false }));
  };

  const handleClickOpenDelete = () => {
    setState((prev) => ({ ...prev, dialogSave: true }));
  };

  const calculateSums = (rows) => {
    return rows.reduce(
      (acc, row) => {
        acc.invoice_boxes += row.invoice_boxes || 0;

        acc.invoice_boxes = parseFloat(acc.invoice_boxes.toFixed(3));
        return acc;
      },
      {
        invoice_boxes: 0,
      }
    );
  };

  const totals = calculateSums(state.data);

  const handleCloseDelete = () => {
    setState((prev) => ({ ...prev, dialogSave: false }));
  };
  return (
    <div className={classes.root}>
      <ViewTitle sedeFlag title="Komet Sales" />

      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={12} md={4}>
          <FormGroup onClick={handleRequestModal}>
            <FormLabel>{"Generate Sales Report"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                readOnly
                type="text"
                placeholder="Generate Sales Report"
                value={state.requestRangeModal.dateRange}
                disabled={state.savingReports}
                endAdornment={<Icon>{"calendar_month"}</Icon>}
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4} className={classes.buttonRequestContainer}>
          <Button
            variant="contained"
            className={classes.acceptButton}
            onClick={addRequest}
            disabled={state.savingReports}
          >
            {"Generate Report"}
          </Button>
          <Button
            variant="contained"
            className={classes.deleteReportButton}
            onClick={handleClickOpenDelete}
            disabled={state.savingReports}
          >
            {"Delete Report"}
          </Button>
        </Grid>
      </Grid>
      <DateRange
        open={state.requestRangeModal.open}
        range={state.requestRangeModal.range}
        minDate={state.requestRangeModal.minDate}
        maxDate={state.requestRangeModal.maxDate}
        onClose={handleRequestModal}
        onChange={handleRequestDateChange}
      />
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormGroup onClick={() => handleModal("invoice")}>
            <FormLabel>{"Invoice Date Range"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                readOnly
                type="text"
                placeholder="Select Invoice Date Range"
                value={state.filter.invoiceDateRange}
                endAdornment={<Icon>{"calendar_month"}</Icon>}
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <FilterInput
          label="Legacy Code"
          name="legacy_code"
          value={state.filter.legacy_code}
          handleAutocomplete={handleAutocomplete}
        />
        <FilterInput
          label="Invoice Number"
          name="invoice_number"
          value={state.filter.invoice_number}
          handleAutocomplete={handleAutocomplete}
        />
        <MultipleSelectCheckmarks
          updateState={handleAutocomplete}
          onCleanCategory={(cleanCategory) =>
            (cleanCategoryRef.current = cleanCategory)
          }
        />
        <FilterInput
          label="Product"
          name="product"
          value={state.filter.product}
          handleAutocomplete={handleAutocomplete}
        />
      </Grid>

      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          className={classes.clear}
          onClick={handleClean}
        >
          {"Clear"}
        </Button>
        <Button variant="contained" className={classes.search} onClick={search}>
          {"Search"}
        </Button>
      </div>

      <Grid container spacing={2} className={classes.totalBox}>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Typography variant="body1">
            Invoice Boxes: {totals.invoice_boxes}
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.tableContainer}>
        <DataGrid
          rows={state.data}
          columns={columns}
          pageSize={state.pageSize}
          onPageSizeChange={(newPageSize) =>
            setState((prev) => ({
              ...prev,
              pageSize: newPageSize,
            }))
          }
          rowsPerPageOptions={[25, 50, 100]}
          density="compact"
          autoHeight
          getRowId={(row) => row.idkomet_sale}
        />
      </div>

      <DateRange
        open={state.rangeModal.open && state.rangeModal.modalKey === "invoice"}
        range={state.rangeModal.range}
        onClose={() => handleModal("invoice")}
        onChange={(item) => handleDateChange(item, "invoice")}
      />

      <Dialog
        open={state.dialogSave}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You´re going to delete the report.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Disagree</Button>
          <Button
            className={classes.deleteReportButton2}
            onClick={destroyReport}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Sales;
