import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FilledInput,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Icon,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import helpers from "../../utils/helpers";
import DateRange from "../../common/date-range";
import { get, post } from "../../utils/api-services";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";
import AuthContext from "../../context";
import FilterInput from "./FilterInput";
import ViewTitle from "../../common/view-title";
import { ToggleButton } from "@material-ui/lab";
import CustomAutocomplete from "./CustomAutocomplete";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#EEE",
  },
  addNewRowButton: {
    backgroundColor: "#198754",
    borderColor: "#198754",
    marginTop: 20,
    marginLeft: 10,
    width: "5%",
    "&:hover": {
      backgroundColor: "#198754",
      borderColor: "#198754",
      boxShadow: "none",
    },
  },
  agreeButton: {
    backgroundColor: "#198754",
    borderColor: "#198754",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#198754",
      borderColor: "#198754",
      boxShadow: "none",
    },
  },
  clearButton: {
    backgroundColor: "#A4A4A4",
    borderColor: "#A4A4A4",
    "&:hover": {
      backgroundColor: "#A4A4A4",
      borderColor: "#A4A4A4",
      boxShadow: "none",
    },
  },
  tableContainer: {
    width: "100%",
    marginTop: 20,
  },
  totalBox: {
    justifyContent: "space-around",
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    marginBottom: 15,
  },
  clear: {
    backgroundColor: "#A4A4A4",
    borderColor: "#A4A4A4",
    marginTop: 20,
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#A4A4A4",
      borderColor: "#A4A4A4",
      boxShadow: "none",
    },
  },
  search: {
    backgroundColor: "#0d6efd",
    borderColor: "#0d6efd",
    marginTop: 20,
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#0d6efd",
      borderColor: "#0d6efd",
      boxShadow: "none",
    },
  },
  buttonsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  acceptButton: {
    backgroundColor: "#198754",
    borderColor: "#198754",
    marginTop: 20,
    width: "60%",
    "&:hover": {
      backgroundColor: "#198754",
      borderColor: "#198754",
      boxShadow: "none",
    },
  },
  deleteReportButton: {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    "&:hover": {
      backgroundColor: "#c82333",
      borderColor: "#bd2130",
      boxShadow: "none",
    },
  },
  requiredValue: {
    fontWeight: "600",
    color: "rgba(255,0,0,1)",
  },
  divider: {
    marginBottom: 10,
  },
  totals: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginBottom: 30,
  },
  centeredLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  inputColor: {
    backgroundColor: "white",
  },
}));

export default function InventoryDetailHistory({ open, handleClose, columns }) {
  const { showToast, signOut, showLoader } = useContext(AuthContext);
  const classes = useStyles();
  const [state, setState] = useState({
    data: [],
    rowToEdit: [],
    dialogDelete: false,
    dialogDeleteItem: false,
    dialogNewRow: false,
    dialogEdit: false,
    showList: true,
    itemToDelete: {},
    pageSize: 50,
    autoCompleteData: {},
    newRow: {
      komet_category: "",
      legacy_code: "",
      transfer: "N",
      total_cooler: "",
      invoice: "",
      po: "",
      supplier_cx: "",
      awb: "",
      reference: "",
      komet_location: "",
      product: "",
      customer: "",
      entry_date: "",
      vendor_dame: "",
      aging: "",
      quantity: "",
      fbe: "",
      box_type: "",
      units: "",
      unit_type: "",
      bunches: "",
      stems_bunch: "",
      total_units: "",
      unit_cost: "",
      landed_cost: "",
      mark_code: "",
    },
    filter: {
      entryDateRange: `${dayjs()
        .subtract(7, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
      reportDateRange: `${dayjs()
        .subtract(7, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
      legacy_code: "",
      product: "",
      reference: "",
    },
    rangeModal: {
      open: false,
      range: [
        {
          startDate: new Date(dayjs().subtract(2, "day").toDate()),
          endDate: new Date(),
          key: "entry",
        },
      ],
      rangeReport: [
        {
          startDate: new Date(dayjs().subtract(2, "day").toDate()),
          endDate: new Date(),
          key: "report",
        },
      ],
    },
  });

  const updatedColumns = columns.map((col) => {
    if (col.field === "idkomet_inventory_detail_log") {
      return { ...col, field: "idkomet_inventory_detail_history" };
    }
    return col;
  });

  const newColumns = [
    {
      field: "actions",
      headerName: "",
      flex: 0.5,
      minWidth: 40,
      renderCell: (params) => (
        <Icon
          onClick={(event) => {
            event.stopPropagation();
            handleClickOpenDeleteItem(params.row);
          }}
        >
          {"delete"}
        </Icon>
      ),
    },
    ...updatedColumns,
  ];

  const handleDeleteClick = async () => {
    await get("/kometInventoryDetailHistory/destroyItem", {
      idkomet_inventory_detail_history:
        state.itemToDelete.idkomet_inventory_detail_history,
    });
    setState((prev) => ({
      ...prev,
      dialogDeleteItem: false,
    }));
    makeRequest();
  };

  useEffect(() => {
    window.document.title = "Komet Inventory Detail History";
    makeRequest();
  }, []);

  const makeRequest = async () => {
    const response = await get(
      "/kometInventoryDetailHistory/index",
      state.filter
    );

    const autoCompleteData = await get("/kometData/autoCompleteData");

    if (autoCompleteData.status === 200) {
      setState((prev) => ({
        ...prev,
        loading: false,
        autoCompleteData: autoCompleteData.data,
      }));
      console.log(autoCompleteData.data);
    } else {
      console.log(
        "[InventoryDetailHistoryReport] makeRequest error",
        autoCompleteData
      );
    }

    if (response.status === 200) {
      setState((prev) => ({ ...prev, loading: false, data: response.data }));
    } else {
      console.log("[InventoryDetailHistoryReport] makeRequest error", response);
    }
  };

  const search = async () => {
    const { filter } = state;

    const hasInvalidCharacter = Object.values(filter).some((value) =>
      value.includes("#")
    );
    if (hasInvalidCharacter) {
      showToast("Filters cannot contain the '#' character", "error");
      return;
    }

    const trimmedFilter = {
      ...filter,
      legacy_code: filter.legacy_code.trim().replace(/\s+/g, " "),
      product: filter.product.trim().replace(/\s+/g, " "),
      reference: filter.reference.trim().replace(/\s+/g, " "),
    };

    showLoader(true);

    const response = await get(
      "/kometInventoryDetailHistory/search",
      trimmedFilter
    );
    if (response.status === 200) {
      setState((prev) => ({ ...prev, data: response.data }));
      if (!response?.data[0])
        showToast("There are no data with these filters", "warning");
    } else {
      console.log("[Inventory] search error", response);
      helpers.failRequest(response, showToast, signOut);
    }
    showLoader(false);
  };

  const deleteByCurrentDay = async () => {
    const response = await get("/kometInventoryDetailHistory/destroyByDate");
    setState((prev) => ({ ...prev, data: response.data }));
    showToast("Current day items deleted", "error");
    setState((prev) => ({ ...prev, dialogDelete: false }));
  };

  const handleModal = (key) => {
    setState((prev) => ({
      ...prev,
      rangeModal: {
        ...prev.rangeModal,
        open: !prev.rangeModal.open,
        modalKey: key,
      },
    }));
  };

  const handleAutocomplete = (key, value) => {
    setState((prev) => ({
      ...prev,
      filter: { ...prev.filter, [key]: value },
    }));
  };

  const handleCleanNewItem = () => {
    setState((prev) => ({
      ...prev,
      newRow: Object.keys(prev.newRow).reduce((acc, key) => {
        acc[key] = "";
        return acc;
      }, {}),
    }));
  };

  const handleClean = () => {
    setState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        ...Object.keys(prev.newRow).reduce((acc, key) => {
          acc[key] = "";
          return acc;
        }, {}),
        entryDateRange: "",
        reportDateRange: "",
      },
    }));
  };

  const handleDateChange = (item, key) => {
    const { startDate, endDate } = item[key];

    const adjustedStartDate = dayjs(startDate)
      .hour(0)
      .minute(1)
      .second(0)
      .millisecond(0);
    const adjustedEndDate = dayjs(endDate)
      .hour(23)
      .minute(59)
      .second(0)
      .millisecond(0);

    const dateRange = `${adjustedStartDate.format(
      "MM/DD/YYYY"
    )} - ${adjustedEndDate.format("MM/DD/YYYY")}`;

    setState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        [`${key}DateRange`]: dateRange,
        [`${key}_start_date`]: adjustedStartDate.format("YYYY-MM-DD HH:mm"),
        [`${key}_end_date`]: adjustedEndDate.format("YYYY-MM-DD HH:mm"),
      },
      rangeModal: {
        ...prev.rangeModal,
        [key === "entry" ? "range" : key === "report" ? "rangeReport" : ""]: [
          item[key],
        ],
      },
    }));
  };

  const calculateSums = (rows) => {
    return rows.reduce(
      (acc, row) => {
        acc.quantity += row.quantity || 0;
        acc.total_cooler += row.total_cooler || 0;
        acc.total_units += row.total_units || 0;
        acc.unit_cost += row.unit_cost || 0;
        acc.landed_cost += row.landed_cost || 0;
        acc.total_cost += row.total_cost || 0;

        acc.quantity = parseFloat(acc.quantity.toFixed(3));
        acc.total_cooler = parseFloat(acc.total_cooler.toFixed(3));
        acc.total_units = parseFloat(acc.total_units.toFixed(3));
        acc.unit_cost = parseFloat(acc.unit_cost.toFixed(3));
        acc.landed_cost = parseFloat(acc.landed_cost.toFixed(3));
        acc.total_cost = parseFloat(acc.total_cost.toFixed(3));

        return acc;
      },
      {
        quantity: 0,
        total_cooler: 0,
        total_units: 0,
        unit_cost: 0,
        landed_cost: 0,
        total_cost: 0,
      }
    );
  };

  const totals = calculateSums(state.data);

  const handleClickOpenDelete = () => {
    setState((prev) => ({ ...prev, dialogDelete: true }));
  };
  const handleCloseDelete = () => {
    setState((prev) => ({ ...prev, dialogDelete: false }));
  };

  const handleClickOpenDeleteItem = (params) => {
    setState((prev) => ({
      ...prev,
      itemToDelete: params,
      dialogDeleteItem: true,
    }));
  };
  const handleCloseDeleteItem = () => {
    setState((prev) => ({ ...prev, dialogDeleteItem: false }));
  };

  const handleCloseNewRow = () => {
    setState((prev) => ({
      ...prev,
      dialogNewRow: false,
    }));
  };

  const saveRow = async () => {
    try {
      console.log(state.newRow);
      const response = await post("/kometInventoryDetailHistory/saveOneRow", {
        ...state.newRow,
      });
      if (response.status === 200) {
        setState((prev) => ({
          ...prev,
          loading: false,
          dialogNewRow: false,
          data: [response.data, ...prev.data],
          newRow: {
            transfer: prev.newRow.transfer,
          },
        }));
        showToast("Item saved", "success");
      } else {
        showToast(response.message, "warning");
      }
    } catch (error) {
      showToast(`Error saving the row. ${error}`, "error");
    }

    if (state.newRow.komet_category === "") {
      setState((prev) => ({
        ...prev,
        newRow: {
          ...prev.newRow,
          komet_category: "",
        },
      }));
    }
  };

  const handleAutocompleteNew = (key, value, index = undefined) => {
    setState((prev) => ({
      ...prev,
      newRow: { ...prev.newRow, [key]: value },
    }));

    if (key === "legacy_code" && index !== undefined) {
      const selectedData = state.autoCompleteData.autoCompleteData.find(
        (item) => item.legacy_code === value
      );

      if (selectedData) {
        setState((prev) => ({
          ...prev,
          newRow: {
            ...prev.newRow,
            product: selectedData.product,
            box_type: selectedData.box_type,
            unit_type: selectedData.unit_type,
            bunches: selectedData.bunches,
            stems_bunch: selectedData.stems_bunch,
            unit_cost: (selectedData.unit_cost || 0).toFixed(2),
            landed_cost: (selectedData.landed_cost || 0).toFixed(2),
          },
        }));
      }
    }
  };

  const handleOpenNewRow = () => {
    setState((prev) => ({ ...prev, dialogNewRow: true }));
  };

  const editRow = async () => {
    try {
      const updatedData = {
        supplier_cx: state.rowToEdit.supplier_cx,
        quantity: Number(state.rowToEdit.quantity),
        mark_code: state.rowToEdit.mark_code,
        reference: state.rowToEdit.reference,
        transfer: state.rowToEdit.transfer,
        total_cooler: Number(state.rowToEdit.total_cooler),
        unit_cost:
          state.rowToEdit.transfer === "Y"
            ? 0
            : Number(state.rowToEdit.unit_cost),
        idkomet_inventory_detail_history:
          state.rowToEdit.idkomet_inventory_detail_history,
        landed_cost: Number(state.rowToEdit.landed_cost),
        total_units: Number(state.rowToEdit.total_units),
      };

      await post("/kometInventoryDetailHistory/update", updatedData);

      setState((prevState) => ({
        ...prevState,
        data: prevState.data.map((item) =>
          item.idkomet_inventory_detail_history ===
          state.rowToEdit.idkomet_inventory_detail_history
            ? { ...item, ...updatedData }
            : item
        ),
      }));
      showToast("Changes saved", "success");
    } catch (error) {
      console.error("Error updating row:", error);
    }
    setState((prev) => ({ ...prev, dialogEdit: false }));
    makeRequest();
  };

  const handleOpenEditDialog = (params) => {
    if (
      JSON.parse(localStorage.getItem("@auth_user")).special_permission === true
    ) {
      setState((prev) => ({
        ...prev,
        dialogEdit: true,
        rowToEdit: params.row,
      }));
    }
  };

  const handleAutocompleteEdit = (key, value) => {
    setState((prev) => ({
      ...prev,
      rowToEdit: { ...prev.rowToEdit, [key]: value },
    }));
  };

  const handleCloseEdit = () => {
    setState((prev) => ({ ...prev, dialogEdit: false }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      PaperProps={{
        style: {
          maxWidth: "90vw",
        },
      }}
    >
      <DialogContent className={classes.root}>
        <ViewTitle sedeFlag title="Komet Inventory Detail History" />

        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormGroup onClick={() => handleModal("entry")}>
              <FormLabel>{"Entry Date Range"}</FormLabel>
              <FormControl variant="filled" component={Box} width="100%">
                <FilledInput
                  readOnly
                  type="text"
                  placeholder="Select Date Range"
                  value={state.filter.entryDateRange}
                  endAdornment={<Icon>{"calendar_month"}</Icon>}
                />
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormGroup onClick={() => handleModal("report")}>
              <FormLabel>{"Report Date Range"}</FormLabel>
              <FormControl variant="filled" component={Box} width="100%">
                <FilledInput
                  readOnly
                  type="text"
                  placeholder="Select Date Range"
                  value={state.filter.reportDateRange}
                  endAdornment={<Icon>{"calendar_month"}</Icon>}
                />
              </FormControl>
            </FormGroup>
          </Grid>

          <FilterInput
            label="Legacy Code"
            name="legacy_code"
            value={state.filter.legacy_code}
            handleAutocomplete={handleAutocomplete}
          />
          <FilterInput
            label="Product"
            name="product"
            value={state.filter.product}
            handleAutocomplete={handleAutocomplete}
          />
          <FilterInput
            label="Reference"
            name="reference"
            value={state.filter.reference}
            handleAutocomplete={handleAutocomplete}
          />
        </Grid>
        <div className={classes.buttonsContainer}>
          {JSON.parse(localStorage.getItem("@auth_user")).special_permission ===
          true ? (
            <Button
              variant="contained"
              className={classes.deleteReportButton}
              onClick={handleClickOpenDelete}
            >
              {"Delete Current Day Report"}
            </Button>
          ) : (
            <div></div>
          )}
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            variant="contained"
            className={classes.clear}
            onClick={handleClean}
          >
            {"Clear"}
          </Button>
          <Button
            variant="contained"
            className={classes.search}
            onClick={search}
          >
            {"Search"}
          </Button>
          {JSON.parse(localStorage.getItem("@auth_user")).special_permission ===
          true ? (
            <Button
              variant="contained"
              className={classes.addNewRowButton}
              onClick={handleOpenNewRow}
            >
              <Icon>{"add_circle_outline"}</Icon>
            </Button>
          ) : (
            <div></div>
          )}
        </div>
        <Grid container spacing={2} className={classes.totalBox}>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <Typography variant="body1">
              Quantity:{" "}
              {totals.quantity.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <Typography variant="body1">
              Total Cooler:{" "}
              {totals.total_cooler.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <Typography variant="body1">
              Total Units:{" "}
              {totals.total_units.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <Typography variant="body1">
              Unit Cost: ${" "}
              {totals.unit_cost.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <Typography variant="body1">
              Landed Cost: ${" "}
              {totals.landed_cost.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <Typography variant="body1">
              Total Cost: ${" "}
              {totals.total_cost.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
        </Grid>
        <DataGrid
          rows={state.data}
          columns={newColumns}
          pageSize={state.pageSize}
          onPageSizeChange={(newPageSize) =>
            setState((prev) => ({
              ...prev,
              pageSize: newPageSize,
            }))
          }
          rowsPerPageOptions={[25, 50, 100]}
          density="compact"
          autoHeight
          getRowId={(row) => row.idkomet_inventory_detail_history}
          onRowClick={(params) => {
            handleOpenEditDialog(params);
          }}
        />
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>

      <DateRange
        open={state.rangeModal.open && state.rangeModal.modalKey === "entry"}
        range={state.rangeModal.range}
        onClose={() => handleModal("entry")}
        onChange={(item) => handleDateChange(item, "entry")}
      />

      <DateRange
        open={state.rangeModal.open && state.rangeModal.modalKey === "report"}
        range={state.rangeModal.rangeReport}
        onClose={() => handleModal("report")}
        onChange={(item) => handleDateChange(item, "report")}
      />

      <Dialog
        open={state.dialogDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You´re going to delete the current day items.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.root}>
          <Button onClick={handleCloseDelete}>Disagree</Button>
          <Button
            className={classes.deleteReportButton}
            style={{ color: "white" }}
            onClick={deleteByCurrentDay}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={state.dialogDeleteItem}
        onClose={handleCloseDeleteItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              <strong>You´re going to delete this item:</strong>{" "}
            </p>
            <p>
              <strong>Report Date: </strong>
              {state.itemToDelete.report_date}
            </p>
            <p>
              <strong>Legacy Code: </strong>
              {state.itemToDelete.legacy_code}
            </p>
            <p>
              <strong>Total Cooler: </strong>
              {state.itemToDelete.total_cooler}
            </p>
            <p>
              <strong>Quantity: </strong>
              {state.itemToDelete.quantity}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.root}>
          <Button onClick={handleCloseDeleteItem}>Disagree</Button>
          <Button
            className={classes.deleteReportButton}
            style={{ color: "white" }}
            onClick={handleDeleteClick}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={state.dialogNewRow}
        onClose={handleCloseNewRow}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={classes.root}>
          <DialogContentText id="alert-dialog-description">
            <ToggleButton
              value="check"
              selected={state.showList}
              onChange={() =>
                setState((prev) => ({
                  ...prev,
                  showList: !prev.showList,
                }))
              }
            >
              <Icon>{"checklist"}</Icon>
            </ToggleButton>
          </DialogContentText>
          <Grid container spacing={2} justifyContent="space-around">
            {!state.showList ? (
              <FilterInput
                label="Legacy Code"
                name="legacy_code"
                value={state.newRow.legacy_code}
                handleAutocomplete={handleAutocompleteNew}
                required
              />
            ) : (
              <CustomAutocomplete
                label="Legacy Code"
                field="legacy_code"
                value={state.newRow.legacy_code}
                options={state.autoCompleteData.autoCompleteData}
                optionKey="legacy_code"
                onChange={(field, value, index) =>
                  handleAutocompleteNew(field, value, index)
                }
                placeholder="Legacy Code"
                error={!state.newRow.legacy_code}
              />
            )}

            <Grid item xs={12} md={4}>
              <FormGroup>
                <FormLabel>{"Category"}</FormLabel>
                <FormControl variant="filled" component={Box} width="100%">
                  <Select
                    name={"komet_category"}
                    value={state.newRow.komet_category || ""}
                    onChange={(event) =>
                      handleAutocompleteNew(
                        "komet_category",
                        event.target.value
                      )
                    }
                  >
                    {state.autoCompleteData?.category?.length > 0 ? (
                      state.autoCompleteData.category.map((item) => (
                        <MenuItem key={item.idkomet_category} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No categories available</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormGroup>
                <FormLabel>{"Transfer"}</FormLabel>
                <FormControl variant="filled" component={Box} width="100%">
                  <Select
                    name={"transfer"}
                    value={state.newRow.transfer}
                    onChange={(event) =>
                      handleAutocompleteNew("transfer", event.target.value)
                    }
                  >
                    <MenuItem value="Y">Y</MenuItem>
                    <MenuItem value="N">N</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            {!state.showList ? (
              <FilterInput
                label="Product"
                name="product"
                value={state.newRow.product}
                required
                createBox
                handleAutocomplete={handleAutocompleteNew}
              />
            ) : (
              <CustomAutocomplete
                label="Product"
                field="product"
                value={state.newRow.product}
                options={state.autoCompleteData.autoCompleteData}
                optionKey="product"
                onChange={(field, value) => handleAutocompleteNew(field, value)}
                placeholder="Product"
                error={!state.newRow.product}
              />
            )}
            <Grid item xs={12} md={4}>
              <FormGroup>
                <FormLabel>{"Supplier CX"}</FormLabel>
                <FormControl variant="filled" component={Box} width="100%">
                  <Select
                    name={"supplier_cx"}
                    value={state.newRow.supplier_cx || ""}
                    onChange={(event) =>
                      handleAutocompleteNew("supplier_cx", event.target.value)
                    }
                  >
                    {state.autoCompleteData?.supplierCx?.length > 0 ? (
                      state.autoCompleteData.supplierCx.map((item) => (
                        <MenuItem
                          key={item.idkomet_supplier_cx}
                          value={item.name}
                        >
                          {item.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No categories available</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <FilterInput
              label="Entry Date"
              name="entry_date"
              type="date"
              value={state.newRow.entry_date}
              required
              handleAutocomplete={handleAutocompleteNew}
            />
            <FilterInput
              label="Total Cooler"
              name="total_cooler"
              type="number"
              value={state.newRow.total_cooler}
              required
              handleAutocomplete={handleAutocompleteNew}
            />
            <FilterInput
              label="Quantity"
              name="quantity"
              type="number"
              value={state.newRow.quantity}
              handleAutocomplete={handleAutocompleteNew}
              required={true}
            />
            <FilterInput
              label="Reference"
              name="reference"
              value={state.newRow.reference}
              editDialog
              handleAutocomplete={handleAutocompleteNew}
            />
            <Grid item xs={12} md={4}>
              <FormGroup>
                <FormLabel>{"Vendor Name"}</FormLabel>
                <FormControl variant="filled" component={Box} width="100%">
                  <Select
                    name={"vendor_dame"}
                    value={state.newRow.vendor_dame || ""}
                    onChange={(event) =>
                      handleAutocompleteNew("vendor_dame", event.target.value)
                    }
                  >
                    {state.autoCompleteData?.vendorName?.length > 0 ? (
                      state.autoCompleteData.vendorName.map((item) => (
                        <MenuItem
                          key={item.idkomet_vendor_name}
                          value={item.name}
                        >
                          {item.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No categories available</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            {!state.showList ? (
              <FilterInput
                label="Box Type"
                name="box_type"
                value={state.newRow.box_type}
                handleAutocomplete={handleAutocompleteNew}
              />
            ) : (
              <CustomAutocomplete
                label="Box Type"
                field="box_type"
                value={state.newRow.box_type}
                options={state.autoCompleteData.autoCompleteData}
                optionKey="box_type"
                onChange={(field, value) => handleAutocompleteNew(field, value)}
                placeholder="Box Type"
              />
            )}
            <FilterInput
              label="Units"
              name="units"
              type="number"
              value={state.newRow.units}
              handleAutocomplete={handleAutocompleteNew}
            />
            {!state.showList ? (
              <FilterInput
                label="Unit Type"
                name="unit_type"
                value={state.newRow.unit_type}
                handleAutocomplete={handleAutocompleteNew}
              />
            ) : (
              <CustomAutocomplete
                label="Unit Type"
                field="unit_type"
                value={state.newRow.unit_type}
                options={state.autoCompleteData.autoCompleteData}
                optionKey="unit_type"
                onChange={(field, value) => handleAutocompleteNew(field, value)}
                placeholder="Unit Type"
              />
            )}
            <FilterInput
              label="Bunches"
              name="bunches"
              type="number"
              value={state.newRow.bunches}
              handleAutocomplete={handleAutocompleteNew}
            />
            <FilterInput
              label="Stems/Bunch"
              name="stems_bunch"
              type="number"
              value={state.newRow.stems_bunch}
              handleAutocomplete={handleAutocompleteNew}
            />
            <FilterInput
              label="Total Units"
              name="total_units"
              type="number"
              value={state.newRow.total_units}
              handleAutocomplete={handleAutocompleteNew}
              required={true}
            />
            <FilterInput
              label="Unit Cost"
              name="unit_cost"
              type="number"
              value={state.newRow.unit_cost}
              handleAutocomplete={handleAutocompleteNew}
              required={true}
            />
            <FilterInput
              label="Landed Cost"
              name="landed_cost"
              type="number"
              value={state.newRow.landed_cost}
              handleAutocomplete={handleAutocompleteNew}
            />

            <FilterInput
              label="Mark Code"
              name="mark_code"
              value={state.newRow.mark_code}
              handleAutocomplete={handleAutocompleteNew}
            />
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.root}>
          <Button onClick={handleCloseNewRow}>Close</Button>
          <Button
            variant="contained"
            className={classes.clearButton}
            onClick={handleCleanNewItem}
          >
            {"Clear"}
          </Button>
          <Button className={classes.agreeButton} onClick={saveRow} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={state.dialogEdit}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={classes.root}>
          <DialogContentText id="alert-dialog-description">
            Legacy Code: {state.rowToEdit.legacy_code}
          </DialogContentText>
          <Grid container spacing={2} justifyContent="space-around">
            <Grid item xs={12} md={4}>
              <FormGroup>
                <FormLabel>{"Transfer"}</FormLabel>
                <FormControl variant="filled" component={Box} width="100%">
                  <Select
                    name={"transfer"}
                    value={state.rowToEdit.transfer}
                    onChange={(event) =>
                      handleAutocompleteEdit("transfer", event.target.value)
                    }
                  >
                    <MenuItem value="Y">Y</MenuItem>
                    <MenuItem value="N">N</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <FilterInput
              label="Supplier CX"
              name="supplier_cx"
              value={state.rowToEdit.supplier_cx}
              handleAutocomplete={handleAutocompleteEdit}
            />
            <FilterInput
              label="Reference"
              name="reference"
              value={state.rowToEdit.reference}
              handleAutocomplete={handleAutocompleteEdit}
              editDialog
            />
            <FilterInput
              label="Total Cooler"
              name="total_cooler"
              type="number"
              value={state.rowToEdit.total_cooler}
              handleAutocomplete={handleAutocompleteEdit}
            />
            <FilterInput
              label="Total Units"
              name="total_units"
              type="number"
              value={state.rowToEdit.total_units}
              handleAutocomplete={handleAutocompleteEdit}
            />
            <FilterInput
              label="Quantity"
              name="quantity"
              type="number"
              value={state.rowToEdit.quantity}
              handleAutocomplete={handleAutocompleteEdit}
            />
            <FilterInput
              label="Unit Cost"
              name="unit_cost"
              type="number"
              value={state.rowToEdit.unit_cost}
              handleAutocomplete={handleAutocompleteEdit}
            />
            <FilterInput
              label="Landed Cost"
              name="landed_cost"
              type="number"
              value={state.rowToEdit.landed_cost}
              handleAutocomplete={handleAutocompleteEdit}
            />

            <FilterInput
              label="Mark Code"
              name="mark_code"
              value={state.rowToEdit.mark_code}
              handleAutocomplete={handleAutocompleteEdit}
            />
            <Grid item xs={12} md={4}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.root}>
          <Button onClick={handleCloseEdit}>Close</Button>
          <Button className={classes.agreeButton} onClick={editRow} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}
