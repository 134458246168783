import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FilledInput,
  Icon,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useLocation } from "react-router-dom";
import ViewTitle from "../../common/view-title";
import AuthContext from "../../context";
import { get } from "../../utils/api-services";
import helpers from "../../utils/helpers";
import DateRange from "../../common/date-range";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import FilterInput from "../components/FilterInput";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingBottom: 20,
  },
  tableContainer: {
    width: "100%",
    marginTop: 20,
  },
  clear: {
    backgroundColor: "#A4A4A4",
    borderColor: "#A4A4A4",
    "&:hover": {
      backgroundColor: "#A4A4A4",
      borderColor: "#A4A4A4",
      boxShadow: "none",
    },
  },
  search: {
    backgroundColor: "#0d6efd",
    borderColor: "#0d6efd",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#0d6efd",
      borderColor: "#0d6efd",
      boxShadow: "none",
    },
    buttonsContainer: {
      padding: 10,
      backgroundColor: "red",
    },
  },
  buttonsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  buttonRequestContainer: {
    width: "100%",
    display: "flex",
    paddingTop: 20,
    paddingBottom: 20,
  },
  acceptButton: {
    backgroundColor: "#198754",
    borderColor: "#198754",
    width: "50%",
    "&:hover": {
      backgroundColor: "#198754",
      borderColor: "#198754",
      boxShadow: "none",
    },
  },
  deleteReportButton: {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    marginLeft: 10,
    width: "50%",
    "&:hover": {
      backgroundColor: "#c82333",
      borderColor: "#bd2130",
      boxShadow: "none",
    },
  },
  deleteReportButton2: {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    width: "20%",
    color: "white",
    "&:hover": {
      backgroundColor: "#c82333",
      borderColor: "#bd2130",
      boxShadow: "none",
    },
  },
  requiredValue: {
    fontWeight: "600",
    color: "rgba(255,0,0,1)",
  },
  divider: {
    marginBottom: 10,
  },
}));

const Shipping = () => {
  const { showToast, signOut, showLoader } = useContext(AuthContext);
  const cleanCategoryRef = useRef(null);
  const classes = useStyles();
  const { pathname } = useLocation();
  const [state, setState] = useState({
    loading: true,
    data: [],
    pageSize: 50,
    dialogDelete: false,
    savingReports: false,
    filter: {
      entryDateRange: `${dayjs()
        .subtract(7, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
      legacy_code: "",
      product: "",
    },
    rangeModal: {
      open: false,
      range: [
        {
          startDate: new Date(dayjs().subtract(2, "day").toDate()),
          endDate: new Date(),
          key: "entry",
        },
      ],
    },
    requestRangeModal: {
      open: false,
      dateRange: `${dayjs()
        .subtract(4, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
      startDate: `${dayjs().subtract(4, "day").format("YYYY-MM-DD")}`,
      endDate: `${dayjs().format("YYYY-MM-DD")}`,
      range: [
        {
          startDate: new Date(dayjs().subtract(4, "day").toDate()),
          endDate: new Date(dayjs().toDate()),
          key: "main",
        },
      ],
    },
    fileData: [],
    avoided: [],
    permissions: {},
  });

  useEffect(() => {
    window.document.title = "Komet Shipping Detail";

    validatePermissions();
  }, []);

  const validatePermissions = () => {
    const permissions = helpers.getPermissions(pathname);
    setState((prev) => ({ ...prev, permissions }));

    permissions.read_attr
      ? makeRequest()
      : setState((prev) => ({ ...prev, loading: false }));
  };

  const makeRequest = async () => {
    showLoader(true);
    const response = await get("/kometShippingInventory/index", state.filter);
    if (response.status === 200) {
      setState((prev) => ({ ...prev, loading: false, data: response.data }));
    } else {
      console.log("[ShippingInventoryReport] makeRequest error", response);
      helpers.failRequest(response, showToast, signOut);
    }
    showLoader(false);
  };
  const getDate = (params) => dayjs(params.value).format("MM/DD/YYYY");
  const getMiamiTimeZoneDate = (params) => {
    return dayjs(params.value).tz("America/New_York").format("MM/DD/YYYY");
  };
  const columns = [
    {
      field: "idkomet_shipping_inventory",
      headerName: "Id",
      flex: 0.8,
      minWidth: 100,
    },
    {
      field: "report_date",
      headerName: "Report Date",
      flex: 1,
      minWidth: 150,
      valueGetter: getMiamiTimeZoneDate,
    },
    {
      field: "komet_category",
      headerName: "Category",
      flex: 1,
      minWidth: 150,
    },
    { field: "legacy_code", headerName: "Legacy Code", flex: 1, minWidth: 150 },
    { field: "product", headerName: "Product", flex: 1, minWidth: 250 },
    {
      field: "entry_date",
      headerName: "Entry Date",
      flex: 1,
      minWidth: 150,
      valueGetter: getDate,
    },
    { field: "awb", headerName: "AWB", flex: 1, minWidth: 200 },
    { field: "aging", headerName: "Aging", flex: 1, minWidth: 150 },
    { field: "reference", headerName: "Reference", flex: 1, minWidth: 150 },
    { field: "box_code", headerName: "Box Code", flex: 1, minWidth: 150 },
    { field: "position", headerName: "Position", flex: 1, minWidth: 200 },
    { field: "lot_number", headerName: "Lot Number", flex: 1, minWidth: 150 },
    {
      field: "komet_location",
      headerName: "Location",
      flex: 1,
      minWidth: 150,
    },
    { field: "vendor_name", headerName: "Vendor Name", flex: 1, minWidth: 250 },
    { field: "box_type", headerName: "Box Type", flex: 1, minWidth: 150 },
  ];

  const handleAutocomplete = (key, value) => {
    setState((prev) => ({
      ...prev,
      filter: { ...prev.filter, [key]: value },
    }));
  };

  const handleClean = () => {
    setState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        product: "",
        legacy_code: "",
        supplier_cx: "",
        entryDateRange: "",
        category: "",
        entry_start_date: "",
        entry_end_date: "",
      },
    }));
    if (cleanCategoryRef.current) {
      cleanCategoryRef.current();
    }
  };
  const search = async () => {
    const { filter } = state;

    const hasInvalidCharacter = Object.values(filter).some((value) =>
      value.includes("#")
    );
    if (hasInvalidCharacter) {
      showToast("Filters cannot contain the '#' character", "error");
      return;
    }

    const trimmedFilter = {
      ...filter,
      legacy_code: filter.legacy_code.trim().replace(/\s+/g, " "),
      product: filter.product.trim().replace(/\s+/g, " "),
    };

    showLoader(true);
    const response = await get("/kometShippingInventory/search", trimmedFilter);
    if (response.status === 200) {
      setState((prev) => ({ ...prev, data: response.data }));
      if (!response?.data[0])
        showToast("There are no data with these filters", "warning");
    } else {
      console.log("[Shipping Inventory] search error", response);
      helpers.failRequest(response, showToast, signOut);
    }
    showLoader(false);
  };

  const handleDateChange = (item, key) => {
    const { startDate, endDate } = item[key];
    const dateRange = `${dayjs(startDate.toISOString()).format(
      "MM/DD/YYYY"
    )} - ${dayjs(endDate.toISOString()).format("MM/DD/YYYY")}`;
    setState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        [`${key}DateRange`]: dateRange,
        [`${key}_start_date`]: dayjs(startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        [`${key}_end_date`]: dayjs(endDate.toISOString()).format("YYYY-MM-DD"),
      },
      rangeModal: {
        ...prev.rangeModal,
        [key === "entry" ? "range" : "reportRange"]: [item[key]],
      },
    }));
  };

  const handleModal = (key) => {
    setState((prev) => ({
      ...prev,
      rangeModal: {
        ...prev.rangeModal,
        open: !prev.rangeModal.open,
        modalKey: key,
      },
    }));
  };

  const handleRequestModal = () => {
    setState((prev) => {
      const isClosing = prev.requestRangeModal.open;
      return {
        ...prev,
        requestRangeModal: {
          ...prev.requestRangeModal,
          open: !prev.requestRangeModal.open,
          ...(isClosing && {
            range: [
              {
                startDate: new Date(dayjs().subtract(4, "day").toDate()),
                endDate: new Date(dayjs().toDate()),
                key: "main",
              },
            ],
            startDate: `${dayjs().subtract(4, "day").format("YYYY-MM-DD")}`,
            endDate: `${dayjs().format("YYYY-MM-DD")}`,
            minDate: null,
            maxDate: null,
          }),
        },
      };
    });
  };

  const handleRequestDateChange = (item) => {
    const { startDate, endDate } = item.main;
    const minDate = dayjs(startDate).subtract(365, "day").toDate();
    const maxDate = dayjs(startDate).add(365, "day").toDate();

    const dateRange = `${dayjs(startDate.toISOString()).format(
      "MM/DD/YYYY"
    )} - ${dayjs(endDate.toISOString()).format("MM/DD/YYYY")}`;
    setState((prev) => ({
      ...prev,
      requestRangeModal: {
        open: true,
        range: [item.main],
        dateRange: dateRange,
        startDate: `${dayjs(startDate.toISOString()).format("YYYY-MM-DD")}`,
        endDate: `${dayjs(endDate.toISOString()).format("YYYY-MM-DD")}`,
        minDate,
        maxDate,
      },
    }));
  };

  const addRequest = async () => {
    try {
      showToast(
        "Your shipping inventory report is being processed.",
        "warning"
      );
      setState((prev) => ({
        ...prev,
        savingReports: true,
      }));
      const saveRequest = await get("/kometShippingInventory/store", {
        startDate: state.requestRangeModal.startDate,
        endDate: state.requestRangeModal.endDate,
      });

      if (
        saveRequest.status === 200 &&
        Array.isArray(saveRequest.data) &&
        saveRequest.data.length > 0
      ) {
        setState((prev) => ({
          ...prev,
          data: saveRequest.data,
          savingReports: false,
        }));
        showToast("Your shipping inventory report is ready", "success");
      } else if (saveRequest.status === 205) {
        setState((prev) => ({
          ...prev,
          savingReports: false,
        }));
        showToast("No data found", "error");
      } else {
        setState((prev) => ({
          ...prev,
          savingReports: false,
        }));
        showToast(saveRequest.message, "error");
      }
    } catch (error) {
      showToast(error, "warning");
    }
  };

  const destroyReport = async () => {
    const saveRequest = await get("/kometShippingInventory/destroy");

    if (saveRequest.status === 200) {
      setState((prev) => ({
        ...prev,
        data: saveRequest.data,
      }));
    } else {
      console.log("[Request] Request error", saveRequest);
    }

    showToast("Reports deleted", "error");
    setState((prev) => ({ ...prev, dialogDelete: false }));
  };

  const handleCloseDelete = () => {
    setState((prev) => ({ ...prev, dialogDelete: false }));
  };

  const handleClickOpenDelete = () => {
    setState((prev) => ({ ...prev, dialogDelete: true }));
  };
  return (
    <div className={classes.root}>
      <ViewTitle sedeFlag title="Komet Shipping Inventory" />

      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={12} md={4}>
          <FormGroup onClick={handleRequestModal}>
            <FormLabel>{"Generate Shipping Inventory Report"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                readOnly
                type="text"
                placeholder="Generate Sales Report"
                value={state.requestRangeModal.dateRange}
                disabled={state.savingReports}
                endAdornment={<Icon>{"calendar_month"}</Icon>}
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4} className={classes.buttonRequestContainer}>
          <Button
            variant="contained"
            className={classes.acceptButton}
            onClick={addRequest}
            disabled={state.savingReports}
          >
            {"Generate Report"}
          </Button>
          <Button
            variant="contained"
            className={classes.deleteReportButton}
            onClick={handleClickOpenDelete}
            disabled={state.savingReports}
          >
            {"Delete Report"}
          </Button>
        </Grid>
      </Grid>
      <DateRange
        open={state.requestRangeModal.open}
        range={state.requestRangeModal.range}
        minDate={state.requestRangeModal.minDate}
        maxDate={state.requestRangeModal.maxDate}
        onClose={handleRequestModal}
        onChange={handleRequestDateChange}
      />
      <Divider className={classes.divider} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormGroup onClick={() => handleModal("entry")}>
            <FormLabel>{"Entry Date Range"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                readOnly
                type="text"
                placeholder="Entry Date Range"
                value={state.filter.entryDateRange}
                endAdornment={<Icon>{"calendar_month"}</Icon>}
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <FilterInput
          label="Legacy Code"
          name="legacy_code"
          value={state.filter.legacy_code}
          handleAutocomplete={handleAutocomplete}
        />
        <MultipleSelectCheckmarks
          updateState={handleAutocomplete}
          onCleanCategory={(cleanCategory) =>
            (cleanCategoryRef.current = cleanCategory)
          }
        />
        <FilterInput
          label="Product"
          name="product"
          value={state.filter.product}
          handleAutocomplete={handleAutocomplete}
        />
      </Grid>
      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          className={classes.clear}
          onClick={handleClean}
        >
          {"Clear"}
        </Button>
        <Button variant="contained" className={classes.search} onClick={search}>
          {"Search"}
        </Button>
      </div>

      <div className={classes.tableContainer}>
        <DataGrid
          rows={state.data}
          columns={columns}
          pageSize={state.pageSize}
          onPageSizeChange={(newPageSize) =>
            setState((prev) => ({
              ...prev,
              pageSize: newPageSize,
            }))
          }
          rowsPerPageOptions={[25, 50, 100]}
          density="compact"
          autoHeight
          getRowId={(row) => row.idkomet_shipping_inventory}
        />
      </div>

      <DateRange
        open={state.rangeModal.open && state.rangeModal.modalKey === "entry"}
        range={state.rangeModal.range}
        onClose={() => handleModal("entry")}
        onChange={(item) => handleDateChange(item, "entry")}
      />

      <Dialog
        open={state.dialogDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You´re going to delete the report.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Disagree</Button>
          <Button
            className={classes.deleteReportButton2}
            onClick={destroyReport}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Shipping;
