import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, FormControlLabel, Checkbox, Button, Tooltip, IconButton, Icon, TextField } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';
import Trash from '../../common/trash';
import EmployeeToPrint from '../components/employee-to-print';
import EmployeeSede from '../components/employee-sede';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%',
        '& .deactivated': {
            backgroundColor: '#F8E0E0'
        }
    },
    employeesToPrint: {
        display: 'none'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    print: {
        marginRight: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none'
        }
    }
}));

const defSede = { id: 0, name: 'Choose a sede' };
const defCC1 = { id: 0, name: 'Choose a cost center' };
const defCC2 = { id: 0, name: 'Choose a cost center 2' };
const defCC3 = { id: 0, name: 'Choose a cost center 3' };

const Employees = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [modalState, setModalState] = useState({ visible: false, employee: {} });
    const [state, setState] = useState({
        loading: true,
        sedes: [],
        employees: [],
        cc1: [],
        cc2: [],
        cc3: [],
        employee: {
            idemployee: 0,
            code: '',
            name: '',
            base_rate: 0,
            indicator: 0,
            active: true,
            idsede: 0,
            sede: defSede,
            is_supplier: false,
            payment: true,
            regular_overtime: true,
            bonus_payment: true,
            biweekly_payment: false,
            cc1: defCC1,
            cc2: defCC2,
            cc3: defCC3
        },
        employeesToPrint: [],
        permissions: {}
    });

    const printRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current
    });

    useEffect(() => {
        window.document.title = 'Employees';

        validatePermissions();
    }, []);

    useEffect(() => {
        if (state.employeesToPrint.length > 0) {
            handlePrint();
        }
    }, [state.employeesToPrint]);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/employees');
        if (response.status === 200) {
            setState(prev => ({
                ...prev, loading: false,
                employees: response?.data ?? [],
                sedes: response?.sedes ?? [],
                cc1: response?.cc1 ?? [],
                cc2: response?.cc2 ?? [],
                cc3: response?.cc3 ?? []
            }));
        } else {
            console.log('[Employees] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, employee: { ...prev.employee, [target.name]: target.value } }));

    const handleCheck = ({ target }) => setState(prev => ({ ...prev, employee: { ...prev.employee, [target.name]: target.checked } }));

    const validateForm = (type) => {
        const { idemployee } = state.employee;

        switch (type) {
            case 'update':
                const data = state.employees.filter(value => value.idemployee === idemployee);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.employee);
                    if (before !== after) {
                        return true;
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some employee', 'error');
                    return false;
                }

            case 'delete':
                if (idemployee === 0) {
                    showToast('Select some employee');
                    return false;
                }
                const cond = window.confirm('Are you sure you want to delete this employee?');

                return Boolean(idemployee && cond);
            default:
                break;
        }
    }

    const clear = () => {
        setState(prev => ({
            ...prev,
            employee: {
                ...prev.employee,
                idemployee: 0,
                code: '',
                name: '',
                base_rate: 0,
                active: true,
                idsede: 0,
                sede: defSede,
                is_supplier: false,
                payment: true,
                regular_overtime: true,
                bonus_payment: true,
                biweekly_payment: false,
                cc1: defCC1,
                cc2: defCC2,
                cc3: defCC3
            }
        }));
    }

    const store = async () => {
        showLoader(true);
        const response = await post('/employee/store', state.employee);
        if (response.status === 200) {
            setState(prev => ({ ...prev, employees: response?.data ?? prev.employees }));
            showToast(response.message);
        } else {
            console.log('[Employees] store error', response);
            helpers.failRequest(response, showToast, signOut);
            showLoader(false);

            return false;
        }
        showLoader(false);
    }

    const update = async () => {
        const { idemployee } = state.employee;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/employee/${idemployee}/update`, state.employee);
            if (response.status === 200) {
                setState(prev => ({ ...prev, employees: response?.data ?? prev.employees }));
                showToast(response.message);
            } else {
                console.log('[Employees] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idemployee } = state.employee;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/employee/${idemployee}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, employees: response?.data ?? prev.employees }));
                showToast(response.message);
            } else {
                console.log('[Employees] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const getEmployeesToPrint = async () => {
        const ids = selectedEmployees;

        if (ids.length < 1) {
            showToast('Select some employee', 'error');
            return null;
        }

        let form = { idemployee: ids.join('-') };

        showLoader(true);
        const response = await get('/employees/print', form);
        if (response.status === 200) {
            setState(prev => ({ ...prev, employeesToPrint: response?.data ?? [] }));

            if (response.data?.length === 0) showToast('No employees to print', 'warning');
        } else {
            console.log('[Employees] getEmployeesToPrint error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const valueGetter = ({ value }) => value ? '✓' : '✗';

    const getName = ({ value }) => value?.name ?? 'N/A';

    const renderCell = ({ row }) => {
        return (
            <>
                <Tooltip title='Split salary' placement='top'>
                    <IconButton onClick={() => setModalState({ visible: true, employee: row })}>
                        <Icon>{'call_split'}</Icon>
                    </IconButton>
                </Tooltip>
                <Link to={`/history/employees/${row.idemployee}`} target='_blank'>
                    <Tooltip title='Show history' placement='top'>
                        <IconButton>
                            <Icon>{'history'}</Icon>
                        </IconButton>
                    </Tooltip>
                </Link>
            </>
        );
    }

    const columns = [
        { field: 'code', headerName: 'Code', flex: 0.8, minWidth: 80 },
        { field: 'name', headerName: 'Name', flex: 1.7, minWidth: 170 },
        { field: 'base_rate', headerName: 'Base rate', flex: 0.8, minWidth: 80 },
        { field: 'indicator', headerName: 'Indicator', flex: 0.8, minWidth: 80 },
        { field: 'sede', headerName: 'Sede', flex: 1, minWidth: 100, valueGetter: getName },
        { field: 'is_supplier', headerName: 'Supplier', flex: 0.8, minWidth: 80, valueGetter },
        { field: 'payment', headerName: 'Payment', flex: 0.8, minWidth: 80, valueGetter },
        { field: 'regular_overtime', headerName: 'Regular OT', flex: 0.8, minWidth: 80, valueGetter },
        { field: 'bonus_payment', headerName: 'Bonus payment', flex: 0.8, minWidth: 80, valueGetter },
        { field: 'biweekly_payment', headerName: 'Biweekly payment', flex: 0.8, minWidth: 80, valueGetter },
        { field: 'cc1', headerName: 'CC1', flex: 0.8, minWidth: 80, valueGetter: getName },
        { field: 'cc2', headerName: 'CC2', flex: 0.8, minWidth: 80, valueGetter: getName },
        { field: 'cc3', headerName: 'CC3', flex: 0.8, minWidth: 80, valueGetter: getName },
        { field: 'actions', headerName: 'Actions', flex: 1, minWidth: 100, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Employees' />
            <div>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <FormGroup>
                            <FormLabel>{'Code'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Code'
                                    name='code'
                                    value={state.employee.code}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <FormGroup>
                            <FormLabel>{'Name'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Name'
                                    name='name'
                                    value={state.employee.name}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormGroup>
                            <FormLabel>{'Base rate'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Base rate'
                                    name='base_rate'
                                    value={state.employee.base_rate}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormGroup>
                            <FormLabel>{'Indicator'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Indicator'
                                    name='indicator'
                                    value={state.employee.indicator}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormGroup>
                            <FormLabel>{'Sede'}</FormLabel>

                            <Autocomplete
                                options={state.sedes}
                                value={state.employee.sede}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, sede) => {
                                    setState(prev => ({ ...prev, employee: { ...prev.employee, sede } }));
                                }}
                                renderInput={params => <TextField {...params} placeholder='Sede' />}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <FormGroup>
                            <FormLabel>{'Cost center 1'}</FormLabel>

                            <Autocomplete
                                options={state.cc1}
                                value={state.employee.cc1}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, cc1) => {
                                    setState(prev => ({ ...prev, employee: { ...prev.employee, cc1 } }));
                                }}
                                renderInput={params => <TextField {...params} placeholder='Cost center 1' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormGroup>
                            <FormLabel>{'Cost center 2'}</FormLabel>

                            <Autocomplete
                                options={state.cc2}
                                value={state.employee.cc2}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, cc2) => {
                                    setState(prev => ({ ...prev, employee: { ...prev.employee, cc2 } }));
                                }}
                                renderInput={params => <TextField {...params} placeholder='Cost center 2' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormGroup>
                            <FormLabel>{'Cost center 3'}</FormLabel>

                            <Autocomplete
                                options={state.cc3}
                                value={state.employee.cc3}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, cc3) => {
                                    setState(prev => ({ ...prev, employee: { ...prev.employee, cc3 } }));
                                }}
                                renderInput={params => <TextField {...params} placeholder='Cost center 3' />}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} md={2}>
                        <Tooltip title='Determina para activar/desactivar un empleado del catálogo. Determines active/deactive a catalog employee.' placement='bottom'>
                            <FormControlLabel
                                checked={state.employee.active}
                                control={<Checkbox color='primary' />}
                                label='Active'
                                labelPlacement='top'
                                name='active'
                                onChange={handleCheck}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Tooltip title='Determina si un empleado es ingresado como un suplidor. Determines whether an employee entered is a supplier.' placement='bottom'>
                            <FormControlLabel
                                checked={state.employee.is_supplier}
                                control={<Checkbox color='primary' />}
                                label='Is supplier'
                                labelPlacement='top'
                                name='is_supplier'
                                onChange={handleCheck}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Tooltip title='Activa/desactiva el pago de un empleado del catálogo. Determines active/deactive the payment a catalog employee.' placement='bottom'>
                            <FormControlLabel
                                checked={state.employee.payment}
                                control={<Checkbox color='primary' />}
                                label='Payment'
                                labelPlacement='top'
                                name='payment'
                                onChange={handleCheck}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Tooltip title='
                                Si se activa, las horas extras se calculan con el método normal (1.5 veces las horas trabajadas), si se desactiva, se usa el nuevo cálculo de base rate (producción por piezas).
                                If enabled, overtime is calculated using the normal method (1.5 times the hours worked), if disabled, the new base rate calculation (piece production) is used.
                            '
                            placement='bottom'
                        >
                            <FormControlLabel
                                checked={state.employee.regular_overtime}
                                control={<Checkbox color='primary' />}
                                label='Regular overtime'
                                labelPlacement='top'
                                name='regular_overtime'
                                onChange={handleCheck}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Tooltip title='Determina para activar/desactivar el pago de bonus en función de las piezas vs horas trabajadas. Determines to activate/deactivate the bonus payment based on pieces vs hours worked.' placement='bottom'>
                            <FormControlLabel
                                checked={state.employee.bonus_payment}
                                control={<Checkbox color='primary' />}
                                label='Bonus payment'
                                labelPlacement='top'
                                name='bonus_payment'
                                onChange={handleCheck}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Tooltip title='Determina la frecuencia de pago de un empleado. Activo (2 semanas)/desactivado (1 semana). Determines the frequency of payment for an employee. Active (2 weeks)/deactivated (1 week).' placement='bottom'>
                            <FormControlLabel
                                checked={state.employee.biweekly_payment}
                                control={<Checkbox color='primary' />}
                                label='Biweekly payment'
                                labelPlacement='top'
                                name='biweekly_payment'
                                onChange={handleCheck}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>

            </div>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            <div className={classes.buttonContainer}>
                <div>
                    <Button
                        variant='contained'
                        className={classes.print}
                        onClick={getEmployeesToPrint}
                    >
                        {'Print barcodes'}
                    </Button>
                </div>
                {
                    state.permissions.delete_attr &&
                    <Trash table='employees' id='idemployee' onRefresh={makeRequest} />
                }
            </div>

            <div className={classes.tableContainer}>
                <DataGrid
                    autoHeight
                    pageSize={45}
                    density='compact'
                    columns={columns}
                    rows={state.employees}
                    getRowId={row => row.idemployee}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, employee: row }))}
                    checkboxSelection
                    disableSelectionOnClick
                    onSelectionModelChange={array => setSelectedEmployees(array)}
                    getRowClassName={({ row }) => row.active ? '' : 'deactivated'}
                />
            </div>

            <EmployeeSede open={modalState.visible} onClose={() => setModalState({ visible: false, employee: {} })} employee={modalState.employee} sedes={state.sedes} />

            <div className={classes.employeesToPrint}>
                <div ref={printRef}>
                    <EmployeeToPrint data={state.employeesToPrint} />
                </div>
            </div>
        </div>
    );
}

export default Employees;