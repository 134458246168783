import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, TextField, Button, Typography, Modal } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactExport from 'react-data-export';
import { DateRange } from 'react-date-range';
import isoWeek from 'dayjs/plugin/isoWeek';
import dayjs from 'dayjs';

import Loader from '../../common/loader';

import themeColors from '../../assets/colors';
import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { get } from '../../utils/api-services';
import ViewTitle from '../../common/view-title';

dayjs.extend(isoWeek);

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    search: {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    download: {
        marginRight: 20,
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        borderRadius: 10,
        '@media (min-width: 480px)': {
            width: '80%'
        },
        '@media (min-width: 720px)': {
            width: '50%'
        },
        '@media (min-width: 1000px)': {
            width: '40%'
        }
    },
    modalButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 15
    },
    modalButton: {
        width: '30%'
    }
}));

const ReportsEmployeePay = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        employees: [],
        filter: {
            weeks: `${dayjs().isoWeekYear()}-${dayjs().isoWeek()}`,
            dateRange: `${dayjs().format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`,
            employee: { id: 0, name: 'All employees' }
        },
        data: {},
        rangeModal: {
            open: false,
            weeksInput: `${dayjs().isoWeekYear()}-${dayjs().isoWeek()}`,
            range: [
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        }
    });

    useEffect(() => {
        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/reports/indexEmployeePay', state.filter);
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                employees: response?.employees ?? []
            }));
        } else {
            console.log('[ReportsEmployeePay] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleWeekRangeChange = item => {
        const { startDate, endDate } = item.main;
        const start = dayjs(startDate.toISOString()).format('MM/DD/YYYY');
        const end = dayjs(endDate.toISOString()).format('MM/DD/YYYY');
        const dateRange = `${start} - ${end}`;
        const { weeks } = helpers.getWeeksOfDates(start, end);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                dateRange,
                weeks: weeks.join(',')
            },
            rangeModal: {
                ...prev.rangeModal,
                weeksInput: weeks.join(', '),
                range: [item.main]
            }
        }));
    }

    const handleRangeModal = () => setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

    const handleChangeAutocomplete = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const search = async () => {
        showLoader(true);
        const response = await get('/reports/searchEmployeePay', state.filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, data: response.data }));

            if (!response?.data?.headers[0]) showToast('There are no data with these filters', 'warning');
        } else {
            console.log('[ReportsEmployeePay] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Employee pay' />
            <Grid container>
                <Grid item xs={6}>
                    <FormGroup onClick={handleRangeModal}>
                        <FormLabel>{'Week range'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                readOnly
                                type='text'
                                placeholder='Week range'
                                value={state.rangeModal.weeksInput}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    <FormGroup>
                        <FormLabel>{'Employee'}</FormLabel>
                        <Autocomplete
                            options={state.employees}
                            value={state.filter.employee}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleChangeAutocomplete('employee', value)}
                            renderInput={params => <TextField {...params} placeholder='Employee' />}
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <div className={classes.buttonsContainer}>
                {
                    state.data?.headers?.length > 0 &&
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-employee-pay-report`}
                        element={<Button variant='contained' className={classes.download}>{'Download data'}</Button>}
                    >
                        <ExcelSheet data={state.data.headers} name='Headers'>
                            <ExcelColumn label='Header ID' value='idemployee_pay_header' />
                            <ExcelColumn label='Week' value='week' />
                            <ExcelColumn label='Employee code' value='employee.code' />
                            <ExcelColumn label='Employee name' value='employee.name' />
                            <ExcelColumn label='Sede code' value='sede.code' />
                            <ExcelColumn label='Sede name' value='sede.name' />
                            <ExcelColumn label='Regular hours' value='regular_hours' />
                            <ExcelColumn label='OT hours' value='overtime_hours' />
                            <ExcelColumn label='Total hours' value='hours' />
                            <ExcelColumn label='Base rate' value='base_rate' />
                            <ExcelColumn label='OT base rate' value='overtime_base_rate' />
                            <ExcelColumn label='Bonus' value='bonus' />
                            <ExcelColumn label='Subsidy' value='subsidy' />
                            <ExcelColumn label='Regular hours pay' value='regular_hours_pay' />
                            <ExcelColumn label='OT hours pay' value='overtime_hours_pay' />
                            <ExcelColumn label='Paylocity OT hours pay' value='paylocity_overtime_hours_pay' />
                            <ExcelColumn label='Total paid' value='total' />
                            <ExcelColumn label='Paylocity total paid' value='paylocity_total' />
                        </ExcelSheet>

                        <ExcelSheet data={state.data.subheadersM} name='Subheaders M'>
                            <ExcelColumn label='Header ID' value='idemployee_pay_header' />
                            <ExcelColumn label='Header employee code' value='header.employee.code' />
                            <ExcelColumn label='Header employee name' value='header.employee.name' />
                            <ExcelColumn label='Subheader ID' value='idemployee_pay_subheader' />
                            <ExcelColumn label='Subheader Date' value='date' />
                            <ExcelColumn label='Subheader Total hours' value='hours' />
                            <ExcelColumn label='Subheader OT hours' value='overtime_hours' />
                            <ExcelColumn label='Subheader OT hours pay' value='overtime_hours_pay' />
                            <ExcelColumn label='Subheader Regular hours' value='regular_hours' />
                            <ExcelColumn label='Subheader Regular hours pay' value='regular_hours_pay' />
                            <ExcelColumn label='Subheader Base pay' value='base_pay' />
                            <ExcelColumn label='Subheader Real pay' value='real_pay' />
                            <ExcelColumn label='Subheader Bonus' value='bonus' />
                            <ExcelColumn label='Subheader Subsidy' value='subsidy' />
                            <ExcelColumn label='Detail ID' value='detail.idemployee_pay' />
                            <ExcelColumn label='Detail Position' value='detail.position' />
                            <ExcelColumn label='Detail Product code' value='detail.product.code' />
                            <ExcelColumn label='Detail Product description' value='detail.product.description' />
                            <ExcelColumn label='Detail Product rate' value='detail.product_rate' />
                            <ExcelColumn label='Detail Total qty' value='detail.total_qty' />
                            <ExcelColumn label='Detail Base pay' value='detail.base_pay' />
                        </ExcelSheet>

                        <ExcelSheet data={state.data.subheadersNM} name='Subheaders NM'>
                            <ExcelColumn label='Header ID' value='idemployee_pay_header' />
                            <ExcelColumn label='Header employee code' value='header.employee.code' />
                            <ExcelColumn label='Header employee name' value='header.employee.name' />
                            <ExcelColumn label='Subheader ID' value='idemployee_pay_subheader_nm' />
                            <ExcelColumn label='Subheader Hours' value='hours' />
                            <ExcelColumn label='Subheader Regular hours' value='regular_hours' />
                            <ExcelColumn label='Subheader Regular hours pay' value='regular_hours_pay' />
                            <ExcelColumn label='Subheader OT hours' value='overtime_week' />
                            <ExcelColumn label='Subheader OT hours pay' value='overtime_week_pay' />
                            <ExcelColumn label='Subheader Holiday hours' value='overtime_holiday' />
                            <ExcelColumn label='Subheader Holiday hours pay' value='overtime_holiday_pay' />
                            <ExcelColumn label='Subheader Total paid' value='total' />
                            <ExcelColumn label='Detail ID' value='detail.idemployee_pay_nm' />
                            <ExcelColumn label='Detail Date' value='detail.date' />
                            <ExcelColumn label='Detail Total hours' value='detail.hours' />
                            <ExcelColumn label='Detail Regular hours' value='detail.regular_hours' />
                            <ExcelColumn label='Detail Regular hours pay' value='detail.regular_hours_pay' />
                            <ExcelColumn label='Detail OT hours' value='detail.overtime_week' />
                            <ExcelColumn label='Detail OT hours pay' value='detail.overtime_week_pay' />
                            <ExcelColumn label='Detail Holiday hours pay' value='detail.overtime_week_pay' />
                            <ExcelColumn label='Detail Holiday hours' value='detail.overtime_holiday' />
                            <ExcelColumn label='Detail Holiday hours pay' value='detail.overtime_holiday_pay' />
                            <ExcelColumn label='Detail Total paid' value='detail.total' />
                        </ExcelSheet>
                    </ExcelFile>
                }

                <Button
                    variant='contained'
                    className={classes.search}
                    onClick={search}
                >
                    {'Search'}
                </Button>
            </div>

            <Modal
                open={state.rangeModal.open}
                className={classes.modal}
            >
                <div className={classes.modalContent}>
                    <Typography variant='h2'>{'Select the weeks'}</Typography>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>{'Weeks'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                >
                                    <FilledInput
                                        disabled
                                        type='text'
                                        placeholder='Weeks'
                                        value={state.rangeModal.weeksInput}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>{'Date range'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                >
                                    <FilledInput
                                        disabled
                                        type='text'
                                        placeholder='Date range'
                                        value={state.filter.dateRange}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <DateRange
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        onChange={handleWeekRangeChange}
                        ranges={state.rangeModal.range}
                        disabledDay={d => dayjs(d.toISOString()).format('d') !== '1'}
                    />

                    <div className={classes.modalButtonContainer}>
                        <Button
                            variant='contained'
                            color='secondary'
                            className={classes.modalButton}
                            onClick={() => setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: false } }))}
                        >
                            {'Close'}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );

}

export default ReportsEmployeePay;