import React, { useState } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar } from "@material-ui/core";

import Parent from "../../common/parent";
import SelectPage from "../../common/select-page";
import NotFound from "../../common/not-found";
import Settings from "../../common/module-settings";

import Prebooks from "./prebooks";
import MasterPrebook from "./master-prebook";
import Sales from "./sales";
import Shipping from "./shipping";
import Inventory from "./inventory";

const useStyles = makeStyles((theme) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		padding: theme.spacing(3),
		paddingTop: 0,
	},
}));

export default function KometParent() {
	const classes = useStyles();
	const { path } = useRouteMatch();
	const [module, setModule] = useState(null);

	const update = (module) => {
		if (module) {
			setModule(module);
		}
	};

	return (
		<Parent module={module} updateModule={update}>
			<main className={classes.content}>
				<Toolbar />
				<Switch>
					<Route exact path={path} render={(props) => <SelectPage />} />
					<Route path={`${path}/kometMasterPrebook`} render={(props) => <MasterPrebook {...props} />} />
					<Route path={`${path}/kometPrebooks`} render={(props) => <Prebooks {...props} />} />
					<Route path={`${path}/kometSales`} render={(props) => <Sales {...props} />} />
					<Route path={`${path}/kometShipping`} render={(props) => <Shipping {...props} />} />
					<Route path={`${path}/kometInventory`} render={(props) => <Inventory {...props} />} />
					<Route path={`${path}/settings`} render={(props) => <Settings {...props} module={module} updateModule={update} />} />
					<Route path={`${path}/*`} component={NotFound} />
				</Switch>
			</main>
		</Parent>
	);
}
