import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FilledInput,
  Icon,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useLocation } from "react-router-dom";
import ViewTitle from "../../common/view-title";
import AuthContext from "../../context";
import { get, post } from "../../utils/api-services";
import helpers from "../../utils/helpers";
import DateRange from "../../common/date-range";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import FilterInput from "../components/FilterInput";
import InventoryDetailHistory from "../components/InventoryDetailHistory";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingBottom: 20,
  },
  tableContainer: {
    width: "100%",
    marginTop: 20,
  },
  clear: {
    backgroundColor: "#A4A4A4",
    borderColor: "#A4A4A4",
    "&:hover": {
      backgroundColor: "#A4A4A4",
      borderColor: "#A4A4A4",
      boxShadow: "none",
    },
  },
  totalBox: {
    justifyContent: "space-around",
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  search: {
    backgroundColor: "#0d6efd",
    borderColor: "#0d6efd",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#0d6efd",
      borderColor: "#0d6efd",
      boxShadow: "none",
    },
  },
  buttonsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  acceptButton: {
    backgroundColor: "#198754",
    borderColor: "#198754",
    marginTop: 20,
    marginBottom: 20,
    width: "50%",
    "&:hover": {
      backgroundColor: "#198754",
      borderColor: "#198754",
      boxShadow: "none",
    },
  },
  acceptButton2: {
    backgroundColor: "#198754",
    borderColor: "#198754",
    marginTop: 20,
    width: "5%",
    "&:hover": {
      backgroundColor: "#198754",
      borderColor: "#198754",
      boxShadow: "none",
    },
  },
  viewHistoryReport: {
    backgroundColor: "#283851",
    color: "#ffffff",
    borderColor: "#4a5d78",
    "&:hover": {
      backgroundColor: "#4a5d78",
      borderColor: "#4a5d78",
      boxShadow: "none",
    },
  },

  agreeButton: {
    backgroundColor: "#198754",
    borderColor: "#198754",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#198754",
      borderColor: "#198754",
      boxShadow: "none",
    },
  },
  deleteReportButton: {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    marginTop: 20,
    marginLeft: 10,
    width: "20%",
    "&:hover": {
      backgroundColor: "#c82333",
      borderColor: "#bd2130",
      boxShadow: "none",
    },
  },
  requiredValue: {
    fontWeight: "600",
    color: "rgba(255,0,0,1)",
  },
  divider: {
    marginBottom: 10,
  },
  divider2: {
    marginTop: 50,
  },
  table: {
    width: "25%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1px",
    border: "1px solid black",
  },
  row: {
    display: "contents",
  },
  cell: {
    padding: "8px",
    border: "1px solid black",
  },
}));

const Inventory = () => {
  const { showToast, signOut, showLoader } = useContext(AuthContext);
  const classes = useStyles();
  const cleanCategoryRef = useRef(null);
  const { pathname } = useLocation();
  const [state, setState] = useState({
    loading: true,
    dialogOpen: false,
    data: [],
    dialogSave: false,
    savingReports: false,
    selectedRowsWithCheck: [],
    summary: {},
    inputValues: {
      legacy_code: "",
      product: "",
      supplierCX: "",
    },
    pageSize: 50,
    filter: {
      entryDateRange: `${dayjs()
        .subtract(7, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
      reportDateRange: `${dayjs()
        .subtract(7, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
      legacy_code: "",
      product: "",
      reference: "",
    },
    rangeModal: {
      open: false,
      range: [
        {
          startDate: new Date(dayjs().subtract(2, "day").toDate()),
          endDate: new Date(),
          key: "entry",
        },
      ],
      rangeReport: [
        {
          startDate: new Date(dayjs().subtract(2, "day").toDate()),
          endDate: new Date(),
          key: "report",
        },
      ],
    },
    requestRangeModal: {
      open: false,
      dateRange: `${dayjs()
        .subtract(4, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
      startDate: `${dayjs().subtract(4, "day").format("YYYY-MM-DD")}`,
      endDate: `${dayjs().format("YYYY-MM-DD")}`,
      range: [
        {
          startDate: new Date(dayjs().subtract(4, "day").toDate()),
          endDate: new Date(dayjs().toDate()),
          key: "main",
        },
      ],
    },
    fileData: [],
    avoided: [],
    permissions: {},
  });

  useEffect(() => {
    window.document.title = "Komet Inventory Detail";

    validatePermissions();
  }, []);

  const calculateSums = (rows) => {
    return rows.reduce(
      (acc, row) => {
        acc.quantity += row.quantity || 0;
        acc.total_cooler += row.total_cooler || 0;
        acc.total_units += row.total_units || 0;
        acc.unit_cost += row.unit_cost || 0;
        acc.landed_cost += row.landed_cost || 0;
        acc.total_cost += row.total_cost || 0;

        acc.quantity = parseFloat(acc.quantity.toFixed(3));
        acc.total_cooler = parseFloat(acc.total_cooler.toFixed(3));
        acc.total_units = parseFloat(acc.total_units.toFixed(3));
        acc.unit_cost = parseFloat(acc.unit_cost.toFixed(3));
        acc.landed_cost = parseFloat(acc.landed_cost.toFixed(3));
        acc.total_cost = parseFloat(acc.total_cost.toFixed(3));

        return acc;
      },
      {
        quantity: 0,
        total_cooler: 0,
        total_units: 0,
        unit_cost: 0,
        landed_cost: 0,
        total_cost: 0,
      }
    );
  };

  const totals = calculateSums(state.data);

  const validatePermissions = () => {
    const permissions = helpers.getPermissions(pathname);
    setState((prev) => ({ ...prev, permissions }));

    permissions.read_attr
      ? makeRequest()
      : setState((prev) => ({ ...prev, loading: false }));
  };

  const makeRequest = async () => {
    showLoader(true);
    const response = await get("/kometInventoryDetail/index", state.filter);
    if (response.status === 200) {
      setState((prev) => ({ ...prev, loading: false, data: response.data }));
    } else {
      console.log("[InventoryDetailReport] makeRequest error", response);
      helpers.failRequest(response, showToast, signOut);
    }
    showLoader(false);
  };

  const getDate = (params) => dayjs(params.value).format("MM/DD/YYYY");

  const getMiamiTimeZoneDate = (params) => {
    return dayjs(params.value).tz("America/New_York").format("MM/DD/YYYY");
  };

  const columns = [
    {
      field: "idkomet_inventory_detail_log",
      headerName: "Id",
      flex: 0.8,
      minWidth: 100,
    },
    {
      field: "report_date",
      headerName: "Report Date",
      flex: 1,
      minWidth: 150,
      valueGetter: getMiamiTimeZoneDate,
    },
    {
      field: "komet_category",
      headerName: "Category",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "entry_date",
      headerName: "Entry Date",
      flex: 1,
      minWidth: 200,
      valueGetter: getDate,
    },
    { field: "legacy_code", headerName: "Legacy Code", flex: 1, minWidth: 150 },
    { field: "product", headerName: "Product", flex: 1, minWidth: 350 },
    {
      field: "total_cooler",
      headerName: `Total Cooler`,
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        const value = params.row.total_cooler || 0;
        return value.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      field: "total_cost",
      headerName: `Total Cost`,
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        const value = params.row.total_cost || 0;
        return `$ ${value.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      field: "quantity",
      headerName: `Quantity`,
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        const value = params.row.quantity || 0;
        return value.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },

    { field: "reference", headerName: "Reference", flex: 1, minWidth: 150 },
    { field: "mark_code", headerName: "Mark code", flex: 1, minWidth: 150 },
    { field: "transfer", headerName: "Transfer", flex: 1, minWidth: 150 },
    { field: "supplier_cx", headerName: "Supplier CX", flex: 1, minWidth: 200 },
    { field: "aging", headerName: "Aging", flex: 1, minWidth: 150 },
    { field: "awb", headerName: "AWB", flex: 1, minWidth: 150 },
    {
      field: "komet_location",
      headerName: "Location",
      flex: 1,
      minWidth: 250,
    },
    { field: "customer", headerName: "Customer", flex: 1, minWidth: 150 },
    { field: "vendor_dame", headerName: "Vendor Dame", flex: 1, minWidth: 150 },
    { field: "box_type", headerName: "Box Type", flex: 1, minWidth: 150 },
    { field: "units", headerName: "Units", flex: 1, minWidth: 150 },
    { field: "unit_type", headerName: "Unit Type", flex: 1, minWidth: 150 },
    { field: "bunches", headerName: "Bunches", flex: 1, minWidth: 150 },
    { field: "stems_bunch", headerName: "Stems Bunch", flex: 1, minWidth: 150 },
    {
      field: "total_units",
      headerName: `Total Units`,
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => {
        const value = params.row.total_units || 0;
        return value.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      field: "unit_cost",
      headerName: `Unit Cost`,
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => {
        const { unit_cost } = params.row;
        if (unit_cost || unit_cost === 0) {
          return `$ ${unit_cost.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        }
        return "$0.00";
      },
    },
    {
      field: "landed_cost",
      headerName: `Landed Cost`,
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => {
        const { landed_cost } = params.row;
        if (landed_cost || landed_cost === 0) {
          return `$ ${landed_cost.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        }
        return "$0.00";
      },
    },
    
  ];

  const handleAutocomplete = (key, value) => {
    setState((prev) => ({
      ...prev,
      filter: { ...prev.filter, [key]: value },
    }));
  };

  const handleClean = () => {
    setState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        product: "",
        legacy_code: "",
        reference: "",
        entryDateRange: "",
        reportDateRange: "",
        category: "",
        entry_start_date: "",
        entry_end_date: "",
        report_start_date: "",
        report_end_date: "",
      },
    }));
    if (cleanCategoryRef.current) {
      cleanCategoryRef.current();
    }
  };

  const search = async () => {
    const { filter } = state;

    const hasInvalidCharacter = Object.values(filter).some((value) =>
      value.includes("#")
    );
    if (hasInvalidCharacter) {
      showToast("Filters cannot contain the '#' character", "error");
      return;
    }

    const trimmedFilter = {
      ...filter,
      legacy_code: filter.legacy_code.trim().replace(/\s+/g, " "),
      product: filter.product.trim().replace(/\s+/g, " "),
      reference: filter.reference.trim().replace(/\s+/g, " "),
    };

    showLoader(true);

    const response = await get("/kometInventoryDetail/search", trimmedFilter);
    if (response.status === 200) {
      setState((prev) => ({ ...prev, data: response.data }));
      if (!response?.data[0])
        showToast("There are no data with these filters", "warning");
    } else {
      console.log("[Inventory] search error", response);
      helpers.failRequest(response, showToast, signOut);
    }
    showLoader(false);
  };

  const handleClickOpenSave = () => {
    setState((prev) => ({ ...prev, dialogSave: true }));
  };
  const handleCloseSave = () => {
    setState((prev) => ({ ...prev, dialogSave: false }));
  };

  const indexHistory = async () => {
    setState((prev) => ({ ...prev, dialogOpen: true }));
  };

  const indexHistoryClose = async () => {
    setState((prev) => ({ ...prev, dialogOpen: false }));
  };

  const handleDateChange = (item, key) => {
    const { startDate, endDate } = item[key];

    const adjustedStartDate = dayjs(startDate)
      .hour(0)
      .minute(1)
      .second(0)
      .millisecond(0);
    const adjustedEndDate = dayjs(endDate)
      .hour(23)
      .minute(59)
      .second(0)
      .millisecond(0);

    const dateRange = `${adjustedStartDate.format(
      "MM/DD/YYYY"
    )} - ${adjustedEndDate.format("MM/DD/YYYY")}`;

    setState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        [`${key}DateRange`]: dateRange,
        [`${key}_start_date`]: adjustedStartDate.format("YYYY-MM-DD HH:mm"),
        [`${key}_end_date`]: adjustedEndDate.format("YYYY-MM-DD HH:mm"),
      },
      rangeModal: {
        ...prev.rangeModal,
        [key === "entry" ? "range" : key === "report" ? "rangeReport" : ""]: [
          item[key],
        ],
      },
    }));
  };

  const handleModal = (key) => {
    setState((prev) => ({
      ...prev,
      rangeModal: {
        ...prev.rangeModal,
        open: !prev.rangeModal.open,
        modalKey: key,
      },
    }));
  };

  const handleRequestModal = () => {
    setState((prev) => {
      const isClosing = prev.requestRangeModal.open;
      return {
        ...prev,
        requestRangeModal: {
          ...prev.requestRangeModal,
          open: !prev.requestRangeModal.open,
          ...(isClosing && {
            range: [
              {
                startDate: new Date(dayjs().subtract(4, "day").toDate()),
                endDate: new Date(dayjs().toDate()),
                key: "main",
              },
            ],
            startDate: `${dayjs().subtract(4, "day").format("YYYY-MM-DD")}`,
            endDate: `${dayjs().format("YYYY-MM-DD")}`,
            minDate: null,
            maxDate: null,
          }),
        },
      };
    });
  };

  const handleRequestDateChange = (item) => {
    const { startDate, endDate } = item.main;
    const minDate = dayjs(startDate).subtract(365, "day").toDate();
    const maxDate = dayjs(startDate).add(365, "day").toDate();

    const dateRange = `${dayjs(startDate.toISOString()).format(
      "MM/DD/YYYY"
    )} - ${dayjs(endDate.toISOString()).format("MM/DD/YYYY")}`;
    setState((prev) => ({
      ...prev,
      requestRangeModal: {
        open: true,
        range: [item.main],
        dateRange: dateRange,
        startDate: `${dayjs(startDate.toISOString()).format("YYYY-MM-DD")}`,
        endDate: `${dayjs(endDate.toISOString()).format("YYYY-MM-DD")}`,
        minDate,
        maxDate,
      },
    }));
  };

  const addRequest = async () => {
    try {
      showToast("Your inventory detail report is being processed.", "warning");
      setState((prev) => ({
        ...prev,
        savingReports: true,
      }));
      const saveRequest = await get("/kometInventoryDetail/store", {
        startDate: state.requestRangeModal.startDate,
        endDate: state.requestRangeModal.endDate,
      });

      if (
        saveRequest.status === 200 &&
        Array.isArray(saveRequest.data) &&
        saveRequest.data.length > 0
      ) {
        setState((prev) => ({
          ...prev,
          data: saveRequest.data,
          savingReports: false,
        }));
        showToast("Your inventory detail report is ready", "success");
      } else if (saveRequest.status === 205) {
        setState((prev) => ({
          ...prev,
          savingReports: false,
        }));
        showToast("No data found", "error");
      } else {
        setState((prev) => ({
          ...prev,
          savingReports: false,
        }));
        showToast(saveRequest.message, "error");
      }
    } catch (e) {
      showToast("Your inventory detail report is ready", "success");
    }
  };

  const saveItems = async () => {
    let ids = [];
    if (state.selectedRowsWithCheck.length > 0) {
      ids = state.selectedRowsWithCheck;
    } else {
      ids = state.data.map((item) => item.idkomet_inventory_detail_log);
    }
    await post("/kometInventoryDetailHistory/save", { ids });
    showToast("Items saved in history report", "success");
    setState((prev) => ({ ...prev, dialogSave: false }));
  };

  const handleRowSelection = (selection) => {
    const selectedRows = state.data.filter((row) =>
      selection.includes(row.idkomet_inventory_detail_log)
    );

    const summary = selectedRows.reduce(
      (acc, row) => ({
        quantity: parseFloat(
          (acc.quantity + Number(row.quantity || 0)).toFixed(3)
        ),
        totalCooler: parseFloat(
          (acc.totalCooler + Number(row.total_cooler || 0)).toFixed(3)
        ),
        totalUnits: parseFloat(
          (acc.totalUnits + Number(row.total_units || 0)).toFixed(3)
        ),
        unitCost: parseFloat(
          (acc.unitCost + Number(row.unit_cost || 0)).toFixed(3)
        ),
        landedCost: parseFloat(
          (acc.landedCost + Number(row.landed_cost || 0)).toFixed(3)
        ),
        totalCost: parseFloat(
          (acc.totalCost + Number(row.total_cost || 0)).toFixed(3)
        ),
      }),
      {
        quantity: 0,
        totalCooler: 0,
        totalUnits: 0,
        unitCost: 0,
        landedCost: 0,
        totalCost: 0,
      }
    );

    setState((prev) => ({
      ...prev,
      selectedRowsWithCheck: selection,
      summary,
    }));
  };

  return (
    <div className={classes.root}>
      <ViewTitle sedeFlag title="Komet Inventory Detail" />

      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={12} md={4}>
          <FormGroup onClick={handleRequestModal}>
            <FormLabel>{"Generate Inventory Report"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                readOnly
                type="text"
                placeholder="Generate Inventory Detail Report"
                value={state.requestRangeModal.dateRange}
                disabled={state.savingReports}
                endAdornment={<Icon>{"calendar_month"}</Icon>}
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          className={classes.buttonRequestContainer}
        >
          <Button
            variant="contained"
            className={classes.acceptButton}
            onClick={addRequest}
            disabled={state.savingReports}
          >
            {"Generate Report"}
          </Button>
        </Grid>
      </Grid>
      <DateRange
        open={state.requestRangeModal.open}
        range={state.requestRangeModal.range}
        minDate={state.requestRangeModal.minDate}
        maxDate={state.requestRangeModal.maxDate}
        onClose={handleRequestModal}
        onChange={handleRequestDateChange}
      />
      <Divider className={classes.divider} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormGroup onClick={() => handleModal("entry")}>
            <FormLabel>{"Entry Date Range"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                readOnly
                type="text"
                placeholder="Select Date Range"
                value={state.filter.entryDateRange}
                endAdornment={<Icon>{"calendar_month"}</Icon>}
              />
            </FormControl>
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormGroup onClick={() => handleModal("report")}>
            <FormLabel>{"Report Date Range"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                readOnly
                type="text"
                placeholder="Select Date Range"
                value={state.filter.reportDateRange}
                endAdornment={<Icon>{"calendar_month"}</Icon>}
              />
            </FormControl>
          </FormGroup>
        </Grid>

        <FilterInput
          label="Legacy Code"
          name="legacy_code"
          value={state.filter.legacy_code}
          handleAutocomplete={handleAutocomplete}
        />
        <FilterInput
          label="Product"
          name="product"
          value={state.filter.product}
          handleAutocomplete={handleAutocomplete}
        />
        <FilterInput
          label="Reference"
          name="reference"
          value={state.filter.reference}
          handleAutocomplete={handleAutocomplete}
        />

        <MultipleSelectCheckmarks
          updateState={handleAutocomplete}
          onCleanCategory={(cleanCategory) =>
            (cleanCategoryRef.current = cleanCategory)
          }
        />
      </Grid>

      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          className={classes.clear}
          onClick={handleClean}
        >
          {"Clear"}
        </Button>
        <Button variant="contained" className={classes.search} onClick={search}>
          {"Search"}
        </Button>
      </div>
      <Button
        variant="outlined"
        className={classes.viewHistoryReport}
        onClick={indexHistory}
      >
        {"View History Report"}
      </Button>

      {state.dialogOpen && (
        <InventoryDetailHistory
          open={state.dialogOpen}
          handleClose={indexHistoryClose}
          columns={columns}
        />
      )}
      <Divider className={classes.divider2} />
      {JSON.parse(localStorage.getItem("@auth_user")).special_permission ===
      true ? (
        <div className={classes.buttonsContainer}>
          <Button
            variant="contained"
            className={classes.acceptButton2}
            onClick={handleClickOpenSave}
          >
            {"Save"}
          </Button>
        </div>
      ) : (
        <div></div>
      )}

      <Grid container spacing={2} className={classes.totalBox}>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Typography variant="body1">
            Quantity:{" "}
            {totals.quantity.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Typography variant="body1">
            Total Cooler:{" "}
            {totals.total_cooler.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Typography variant="body1">
            Total Units:{" "}
            {totals.total_units.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Typography variant="body1">
            Unit Cost:{" "}
            $ {totals.unit_cost.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Typography variant="body1">
            Landed Cost:{" "}
            $ {totals.landed_cost.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Typography variant="body1">
            Total Cost:{" "}
            $ {totals.total_cost.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Grid>
      </Grid>

      {state.summary &&
      Object.keys(state.summary).length > 0 &&
      Object.values(state.summary).some((value) => value !== 0) ? (
        <Grid
          container
          spacing={2}
          className={classes.totalBox}
          style={{
            marginTop: "3%",
            backgroundColor: "#F1F1F1",
            fontWeight: "bold",
          }}
        >
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <Typography variant="body1">
              Quantity Summary:{" "}
              {state.summary.quantity.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <Typography variant="body1">
              Total Cooler Summary:{" "}
              {state.summary.totalCooler.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <Typography variant="body1">
              Total Units Summary:{" "}
              {state.summary.totalUnits.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <Typography variant="body1">
              Unit Cost Summary:{" "}
              $ {state.summary.unitCost.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <Typography variant="body1">
              Landed Cost Summary:{" "}
              $ {state.summary.landedCost.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <Typography variant="body1">
              Total Cost Summary:{" "}
              $ {state.summary.totalCost.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      <div className={classes.tableContainer}>
        <DataGrid
          rows={state.data}
          columns={columns}
          pageSize={state.pageSize}
          onPageSizeChange={(newPageSize) =>
            setState((prev) => ({
              ...prev,
              pageSize: newPageSize,
            }))
          }
          rowsPerPageOptions={[25, 50, 100]}
          density="compact"
          autoHeight
          getRowId={(row) => row.idkomet_inventory_detail_log}
          checkboxSelection
          onSelectionModelChange={handleRowSelection}
        />
      </div>

      <DateRange
        open={state.rangeModal.open && state.rangeModal.modalKey === "entry"}
        range={state.rangeModal.range}
        onClose={() => handleModal("entry")}
        onChange={(item) => handleDateChange(item, "entry")}
      />

      <DateRange
        open={state.rangeModal.open && state.rangeModal.modalKey === "report"}
        range={state.rangeModal.rangeReport}
        onClose={() => handleModal("report")}
        onChange={(item) => handleDateChange(item, "report")}
      />

      <Dialog
        open={state.dialogSave}
        onClose={handleCloseSave}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You´re going to save the table information in the history report.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSave}>Disagree</Button>
          <Button className={classes.agreeButton} onClick={saveItems} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Inventory;
