import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, Typography, Button, Icon, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Grid, FormGroup, FormLabel, FormControl, FilledInput, Box } from '@material-ui/core';
import dayjs from 'dayjs';

import Loader from './loader';

import AuthContext from '../context';
import { get, put } from '../utils/api-services';
import helpers from '../utils/helpers';
import themeColors from '../assets/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        borderRadius: 10,
        width: '80%',
        height: '60%'
    },
    buttonContainer: {
        alignSelf: 'flex-end',
    },
    trash: {
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20
    },
    title: {
        margin: 0
    },
    item: {
        paddingTop: 8,
        paddingBottom: 8,
        backgroundColor: themeColors.white.main
    },
    list: {
        flex: 1,
        overflow: 'auto',
        marginTop: 15
    }
}));

const Trash = ({ table, id, onRefresh }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const isMounted = useRef(false);
    const [showModal, setShowModal] = useState(false);
    const [state, setState] = useState({
        loading: true,
        data: [],
        filter: '',
        filteredData: []
    });

    useEffect(() => {
        if (showModal && !isMounted.current) {
            makeRequest();
            isMounted.current = true;
        }
    }, [showModal]);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/universal/trash', { table });
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, data: response.data }));
        } else {
            console.log('[Trash] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => {
        const { data } = state;

        const filter = target.value.toLowerCase().trim();
        const filteredData = [];
        data.forEach(item => {
            const firstColumn = item.code.toLowerCase();
            const secondColumn = item.description.toLowerCase();

            if (firstColumn.indexOf(filter) > -1 || secondColumn.indexOf(filter) > -1) {
                filteredData.push(item);
            }
        });

        setState(prev => ({ ...prev, filteredData, [target.name]: target.value }));
    }

    const restore = async item => {
        const cond = window.confirm('Are you sure you want to restore this item?');
        if (cond) {
            showLoader(true);
            const response = await put(`/universal/${item[id]}/restore`, { table });
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response.data }));
                showToast(response.message);
                onRefresh();
            } else {
                console.log('[Trash] makeRequest error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const renderItem = item => {
        let primary = null;
        let secondary = null;

        if (['locations', 'sedes', 'categories', 'lands', 'products', 'suppliers', 'employees'].includes(table)) {

            primary = `${item.code} - ${item.name}`;

        } else if (table === 'sede_locations') {

            primary = `${item.sede.name} -> ${item.location.name}`;

        } else if (['roles', 'land_owners', 'items', 'types', 'departments', 'product_type', 'cost_center', 'employee_hour_action'].includes(table)) {

            primary = `${item.name} ${(table === 'items') ? item.action : ''}`;

        } else if (table === 'option_roles') {

            primary = `${item.role.name} - ${item.option.name}`;

        } else if (table === 'users') {

            primary = `${item.username} - ${item.names} ${item.surnames}`;

        } else if (table === 'user_sedes') {

            primary = `${item.user.username} -> ${item.sede.name}`;

        } else if (['admin_fees', 'stumpage'].includes(table)) {

            primary = `${item.land?.name ?? 'All'} | ${item?.supplier?.name ?? 'All'} | ${item?.product?.name ?? 'All'}`;
            secondary = `${item.percent ? '%' : '$'} ${item.value}`;

        } else if (table === 'extras') {

            primary = `${item.product.name} | ${item.type.name} | ${item.supplier.name}`;
            secondary = `${dayjs(item.date).format('MM/DD/YYYY')} | $ ${item.value}`;

        } else if (table === 'product_types') {

            primary = `${item.product.name} -> ${item.type.name}`;

        } else if (table === 'product_sales') {

            primary = `${item.code} - ${item.description}`;

        } else if (table === 'stations') {

            primary = `${item.description}`;

        } else if (table === 'product_sale_labor') {

            primary = `${item.code} - ${item.type}`;
            secondary = item.size;

        } else if (table === 'break_time') {

            primary = item.description;
            secondary = `${item.start_time} -> ${item.end_time}`;

        } else if (table === 'cost_center_2') {

            primary = item.code;

        } else if (table === 'cost_center_3') {

            primary = item.code;
            secondary = item.activity;

        } else if(["ff_loader","ff_trucking", "ff_item"].includes(table)){
            primary = `${item.name}`;
        }
        else if(table === "ff_customer"){
            primary = item.name;
            secondary = item.adress;
        }
        else if(table === "ff_product"){
            primary = `${item.code} | ${item.name} | ${item.idff_item}`;
        }
        else if(table === "ff_delivery"){
            primary = `${item.delivery_number} | ${item.date_created}`;
        }
        else if(table === "ff_ticket_bill"){
            primary = `${item.code} | ${item.date}`;
        }
        else if (table === "cashier_control") {
            primary = "";
            secondary = "";
        
            if (item.detail) primary += item.detail;
            if (item.detail_type) primary += primary ? ` | Type: ${item.detail_type}` : `Type: ${item.detail_type}`;
            if (item.check_deposit_number) primary += primary ? ` | Check/Deposit No. ${item.check_deposit_number}` : `Check/Deposit No. ${item.check_deposit_number}`;
            if (item.date) primary += primary ? ` | Date: ${dayjs(item.date).format('MM/DD/YYYY')}` : `Date: ${dayjs(item.date).format('MM/DD/YYYY')}`;
            if (item.week) primary += primary ? ` | Week: ${item.week}` : `Week: ${item.week}`;
        
            if (item.cash) secondary += `Cash: ${item.cash}`;
            if (item.purchase) secondary += secondary ? ` | Purchase: ${item.purchase}` : `Purchase: ${item.purchase}`;
        }
        

        else {
            return <span key={item[id]}>{'Table without rendering method'}</span>
        }

        return (
            <ListItemText
                primary={primary}
                secondary={secondary}
            />
        );
    }

    return (
        <div className={classes.root}>
            <div className={classes.buttonContainer}>
                <Button
                    variant='contained'
                    className={classes.trash}
                    startIcon={<Icon>{'delete'}</Icon>}
                    onClick={() => setShowModal(true)}
                >
                    {'Trash'}
                </Button>
            </div>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                className={classes.modal}
            >
                <div className={classes.container}>
                    {
                        !state.loading ?
                            <>
                                <div className={classes.header}>
                                    <Typography variant='h2' className={classes.title}>{'Trash'}</Typography>
                                    <div>

                                        <IconButton
                                            title='Refresh data'
                                            onClick={makeRequest}
                                        >
                                            <Icon>{'refresh'}</Icon>
                                        </IconButton>
                                        <IconButton
                                            title='Close'
                                            onClick={() => setShowModal(false)}
                                        >
                                            <Icon>{'close'}</Icon>
                                        </IconButton>
                                    </div>
                                </div>
                                {
                                    (table === 'product_sales') &&
                                    <Grid container>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <FormGroup>
                                                <FormLabel>{'Filter'}</FormLabel>
                                                <FormControl
                                                    variant='filled'
                                                    component={Box}
                                                    width='100%'
                                                >
                                                    <FilledInput
                                                        autoComplete='off'
                                                        type='text'
                                                        placeholder='Filter'
                                                        name='filter'
                                                        value={state.filter}
                                                        onChange={handleChange}
                                                    />
                                                </FormControl>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                }
                                <List className={classes.list}>
                                    {
                                        (state.filteredData.length > 0)
                                            ?
                                            state.filteredData.map(item => {
                                                return (
                                                    <ListItem button key={item[id]} className={classes.item}>
                                                        {
                                                            renderItem(item)
                                                        }
                                                        <ListItemSecondaryAction>
                                                            <IconButton title='Restore' onClick={() => restore(item)}>
                                                                <Icon>{'restore_from_trash'}</Icon>
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })
                                            :
                                            state.data.map(item => {
                                                return (
                                                    <ListItem button key={item[id]} className={classes.item}>
                                                        {
                                                            renderItem(item)
                                                        }
                                                        <ListItemSecondaryAction>
                                                            <IconButton title='Restore' onClick={() => restore(item)}>
                                                                <Icon>{'restore_from_trash'}</Icon>
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })
                                    }
                                </List>
                                {
                                    (state.data.length === 0) && <Typography>{'No data in the trash'}</Typography>
                                }
                            </> :
                            <Loader />
                    }
                </div>
            </Modal>
        </div>
    );
}

export default Trash;