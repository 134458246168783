import React, { useReducer, useMemo } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider, Backdrop, CircularProgress, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Loading from './loading';

import NotFound from './common/not-found';
import Login from './auth/containers/login';
import Home from './home/containers/home';
import Security from './security/containers/parent';
import FarmAndForest from "./farmForest/containers/parent";
import Cashier from "./cashier/containers/parent";
import Ticket from './tickets/containers/parent';
import Profile from './home/containers/profile';
import Process from './process/containers/parent';
import BOM from './bom/containers/parent';
import Production from './production/containers/parent';
import History from './common/history';
import Inventory from './inventory/containers/parent';
import Komet from './komet/containers/parent';

import AuthContext from './context';
import theme from './assets/theme';

const App = () => {

    const initialState = {
        isLoading: true,
        userToken: null,
        showLoader: false,
        toast: {
            open: false,
            msg: '',
            severity: 'success',
            duration: 5000
        },
    }

    const reducer = (prevState, action) => {
        switch (action.type) {
            case 'SIGN_IN':
                return {
                    ...prevState,
                    userToken: action.token,
                    isLoading: false,
                }
            case 'SIGN_OUT':
                return {
                    ...prevState,
                    userToken: null,
                    isLoading: false,
                }
            case 'SHOW_TOAST':
                return {
                    ...prevState,
                    toast: {
                        open: true,
                        msg: action.msg,
                        severity: action.severity,
                        duration: action.duration,
                    },
                }
            case 'HIDE_TOAST':
                return {
                    ...prevState,
                    toast: {
                        ...prevState.toast,
                        open: false,
                        msg: '',
                        duration: 5000,
                    },
                }
            case 'SHOW_LOADER':
                return {
                    ...prevState,
                    showLoader: action.shown
                }
            default:
                break;
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    const authContext = useMemo(() => ({
        signIn: (user, token, sede) => {
            localStorage.setItem('@auth_user', JSON.stringify(user));
            localStorage.setItem('@sede', JSON.stringify(sede));
            localStorage.setItem('@token', token);

            dispatch({ token, type: 'SIGN_IN' });
        },
        signOut: () => {
            localStorage.removeItem('@auth_user');
            localStorage.removeItem('@token');

            dispatch({ type: 'SIGN_OUT' });
        },
        showLoader: action => {
            dispatch({ shown: action, type: 'SHOW_LOADER' });
        },
        showToast: (msg, severity = 'success', duration = 4000) => {
            dispatch({ msg, severity, duration, type: 'SHOW_TOAST' });

            setTimeout(() => dispatch({ type: 'HIDE_TOAST' }), duration);
        }
    }), []);

    return (
        <AuthContext.Provider value={authContext}>
            {
                state.isLoading ?
                    <Loading /> :
                    <BrowserRouter>
                        {
                            state.userToken !== null ?
                                <Switch>
                                    <Redirect exact from='/' to='/home' />
                                    <Route path='/home' component={Home} />
                                    <Route path='/security' component={Security} />
                                    <Route path='/ticket' component={Ticket} />
                                    <Route path='/profile' ><Profile /></Route>
                                    <Route path='/process'><Process /></Route>
                                    <Route path='/bom'><BOM /></Route>
                                    <Route path='/production'><Production /></Route>
                                    <Route path='/inventory'><Inventory /></Route>
                                    <Route path='/komet'><Komet /></Route>
									<Route path="/farmForest" component={FarmAndForest}></Route>
                                    <Route path="/cashier" component={Cashier}></Route>
                                    <Route path='/history/:table/:pk'><History /></Route>
                                    <Route path='*' component={NotFound} />
                                </Switch> :
                                <ThemeProvider theme={theme}>
                                    <Route path='*' render={() => <Redirect to='/login' />} />
                                    <Route path='/login' component={Login} />
                                </ThemeProvider>
                        }
                    </BrowserRouter>
            }
            <Backdrop
                open={state.showLoader}
                style={{
                    zIndex: theme.zIndex.snackbar + 1,
                    color: '#fff',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <CircularProgress color="inherit" />
                <span>{'Loading...'}</span>
            </Backdrop>
            <div style={{ position: 'fixed', zIndex: theme.zIndex.snackbar + 1, bottom: 25, left: 25, height: 'auto' }}>
                <Collapse in={state.toast.open}>
                    <Alert
                        severity={state.toast.severity}
                        variant='filled'
                        onClose={() => dispatch({ type: 'HIDE_TOAST' })}
                    >
                        {state.toast.msg}
                    </Alert>
                </Collapse>
            </div>
        </AuthContext.Provider>
    );
}
export default App;
