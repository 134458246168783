import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation } from 'react-router-dom';
import EscPosEncoder from 'esc-pos-encoder';
import dayjs from 'dayjs';

import Loader from '../../common/loader';

import AuthContext from '../../context';
import { get, post } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    store: {
        width: '100%',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    outlined: {
        width: '100%',
        backgroundColor: 'transparent',
        borderColor: '#0d6efd',
        color: 'inherit',
        '&:hover': {
            backgroundColor: 'transparent',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    }
}));

const ProductionsMobile = ({ alone = false }) => {

    const imgRef = useRef(null);
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [printState, setPrintState] = useState({ visible: false, production: {}, id: 0 });
    const [state, setState] = useState({
        loading: true,
        departments: [],
        production: {
            department: { id: 0, name: 'Choose a department', use_ticket: false },
            date: dayjs().format('YYYY-MM-DD'),
            full_position: '',
            station: 0,
            section: '',
            position: 0,
            code_product: '',
            product_sale: {},
            code_employee: '',
            employee: {},
            code_employee_2: '',
            employee_2: {},
            code_ticket: '',
            ticket: {},
            weight: 0,
            product_qty: 0,
            qty: 0,
            total_qty: 0,
            image: ''
        },
        permissions: {}
    });

    useEffect(() => {
        validatePermissions();
    }, []);

    useEffect(() => {
        const { product_qty, qty } = state.production;

        const total_qty = (Number(product_qty) * Number(qty)).toFixed(0);
        setState(prev => ({ ...prev, production: { ...prev.production, total_qty } }));

    }, [state.production.qty]);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/productionsMobile');
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, departments: response?.departments ?? [] }));
        } else {
            console.log('[Productions] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, production: { ...prev.production, [target.name]: target.value } }));

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, production: { ...prev.production, [key]: value } }));

    const validateForm = () => {
        const { department, date, full_position, product_sale, employee, total_qty, weight, employee_2 } = state.production;

        if (alone === false && employee?.id === employee_2?.id) {
            showToast('You cannot use the same employee', 'error');
            return false;
        }

        if (Number(department?.id) && Boolean(date) && full_position.trim().length >= 3 && Number(product_sale?.id) && Number(employee?.id) && Number(total_qty) && Number(weight)) {
            return true;
        } else {
            showToast('All fields is required', 'error');
            return false;
        }
    }

    const searchProductSale = async (e) => {
        const { code_product, department } = state.production;

        if (e.key === 'Enter') {
            showLoader(true);
            const response = await get(`/productionMobile/${code_product}/searchProductSale`);
            if (response.status === 200) {
                let product_qty;
                if (department?.production_pack === true) {
                    product_qty = 1;
                } else {
                    product_qty = response?.data?.pack_qty ?? 1;
                }

                setState(prev => ({ ...prev, production: { ...prev.production, product_qty, product_sale: response?.data ?? {} } }));
            } else {
                console.log('[Productions] searchProductSale error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const searchEmployee = async (e, field) => {
        const { value } = e.target;

        if (e.key === 'Enter') {
            showLoader(true);
            const response = await get(`/productionMobile/${value}/searchEmployee`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, production: { ...prev.production, [field]: response?.data ?? {} } }));
            } else {
                console.log('[Productions] searchEmployee error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const searchTicket = async (e) => {
        const { code_ticket } = state.production;

        if (e.key === 'Enter') {
            showLoader(true);
            const response = await get(`/productionMobile/${code_ticket}/searchTicket`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, production: { ...prev.production, ticket: response?.data ?? {} } }));
            } else {
                setState(prev => ({ ...prev, production: { ...prev.production, ticket: {} } }));
                console.log('[Productions] searchTicket error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const store = async () => {
        const cond = validateForm();

        if (cond) {
            showLoader(true);
            const response = await post('/productionMobile/store', { ...state.production, is_alone: alone });
            if (response.status === 200) {
                setPrintState({ visible: true, production: state.production, id: response.id });
                clear();
                showToast(response.message);
            } else {
                console.log('[Productions] store error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const onPrint = async encoder => {
        try {
            const S = "#Intent;scheme=rawbt;";
            const P = "package=ru.a402d.rawbtprinter;end;";

            const text = String.fromCharCode.apply(null, encoder);
            const textEncoded = encodeURI(text);

            window.location.href = `intent:${textEncoded + S + P}`;
        } catch (e) {
            console.log('[Productions] onPrint eror', e);
            alert('An error occurred when printing');
        }
    }

    const printVoucher = () => {
        const { production } = printState;
        const encoder = new EscPosEncoder();
        const specialPack = Boolean(production?.department?.production_pack === true);

        let voucher;

        if (alone) {
            voucher = encoder.initialize()
                .text('Date: ').text(dayjs(production.date).format('MM/DD/YYYY'))
                .newline()
                .text('Position: ').text(production.full_position)
                .newline()
                .text('Weight: ').text(production.weight)
                .newline()
                .text('Product qty: ').text(`${production.product_qty}${specialPack ? '*' : ''}`)
                .newline()
                .text('Qty: ').text(production.qty)
                .newline()
                .text('Total qty: ').text(production.total_qty)
                .newline()
                .newline()
                .text('Employee')
                .newline()
                .text(production.employee.code)
                .newline()
                .text(production.employee.name)
                .newline()
                .newline()
                .text('Product sale')
                .newline()
                .text(production.product_sale.code)
                .newline()
                .text(production.product_sale.description)
                .newline()
                .newline()
                .text(dayjs().format('[Printed:] MM/DD/YYYY HH:mm'))
                .newline()
                .newline()
                .encode();
        } else {
            voucher = encoder.initialize()
                .text('Date: ').text(dayjs(production.date).format('MM/DD/YYYY'))
                .newline()
                .text('Position: ').text(production.full_position)
                .newline()
                .text('Weight: ').text(production.weight)
                .newline()
                .text('Product qty: ').text(`${production.product_qty}${specialPack ? '*' : ''}`)
                .newline()
                .text('Qty: ').text(production.qty)
                .newline()
                .text('Total qty: ').text(production.total_qty)
                .newline()
                .newline()
                .text('Employee')
                .newline()
                .text(production.employee.code)
                .newline()
                .text(production.employee.name)
                .newline()
                .newline()
                .text('Employee 2')
                .newline()
                .text(production.employee_2.code)
                .newline()
                .text(production.employee_2.name)
                .newline()
                .newline()
                .text('Product sale')
                .newline()
                .text(production.product_sale.code)
                .newline()
                .text(production.product_sale.description)
                .newline()
                .newline()
                .text(dayjs().format('[Printed:] MM/DD/YYYY HH:mm'))
                .newline()
                .newline()
                .encode();
        }

        onPrint(voucher);
    }

    const printId = () => {
        const encoder = new EscPosEncoder();

        const identifier = encoder.initialize()
            .bold(true).text('ID: ').bold(false).text(printState.id)
            .newline()
            .newline()
            .align('center').text(dayjs().format('[Printed:] MM/DD/YYYY HH:mm'))
            .newline()
            .newline()
            .encode();

        onPrint(identifier);
    }

    const handleImageChange = ({ target }) => {
        const file = target?.files[0] ?? '';
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                setState(prev => ({ ...prev, production: { ...prev.production, image: reader.result } }));
            }
        } else {
            setState(prev => ({ ...prev, production: { ...prev.production, image: '' } }));
        }
    }

    const clear = () => {
        if (imgRef.current) {
            imgRef.current.value = '';
        }

        setState(prev => ({
            ...prev,
            production: {
                ...prev.production,
                full_position: '',
                code_product: '',
                product_sale: {},
                code_employee: '',
                employee: {},
                code_employee_2: '',
                employee_2: {},
                code_ticket: '',
                ticket: {},
                weight: 0,
                product_qty: 0,
                qty: 0,
                total_qty: 0,
                image: ''
            }
        }));
    }

    const clearPrintState = () => setPrintState({ visible: false, production: {}, id: 0 });

    if (state.loading) {
        return <Loader />;
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <FormGroup>
                        <FormLabel>{'Department'}</FormLabel>
                        <Autocomplete
                            options={state.departments}
                            value={state.production.department}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => {
                                clear();
                                handleAutocomplete('department', value)
                            }}
                            renderInput={params => <TextField {...params} placeholder='Department' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <FormGroup>
                        <FormLabel>{'Date'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='date'
                                placeholder='Date'
                                name='date'
                                value={state.production.date}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <FormGroup>
                        <FormLabel>{'Station-Section-Position'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Position'
                                name='full_position'
                                value={state.production.full_position}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormGroup>
                        <FormLabel>{'Code - Employee'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Code employee'
                                name='code_employee'
                                value={state.production.code_employee}
                                onChange={handleChange}
                                onKeyPress={e => searchEmployee(e, 'employee')}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormGroup>
                        <FormLabel>{'Employee'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                disabled={true}
                                type='text'
                                placeholder='Employee'
                                value={state.production.employee?.name ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                {
                    (alone === false) &&
                    <>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormGroup>
                                <FormLabel>{'Code - Employee 2'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                >
                                    <FilledInput
                                        autoComplete='off'
                                        type='text'
                                        placeholder='Code employee 2'
                                        name='code_employee_2'
                                        value={state.production.code_employee_2}
                                        onChange={handleChange}
                                        onKeyPress={e => searchEmployee(e, 'employee_2')}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormGroup>
                                <FormLabel>{'Employee 2'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                >
                                    <FilledInput
                                        autoComplete='off'
                                        disabled={true}
                                        type='text'
                                        placeholder='Employee 2'
                                        value={state.production.employee_2?.name ?? ''}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                    </>
                }
                <Grid item xs={12} sm={12} md={12}>
                    <FormGroup>
                        <FormLabel>{'Code - Product sale'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Code product'
                                name='code_product'
                                value={state.production.code_product}
                                onChange={handleChange}
                                onKeyPress={searchProductSale}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <FormGroup>
                        <FormLabel>{'Product sale'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                disabled={true}
                                type='text'
                                placeholder='Product sale'
                                value={state.production.product_sale?.description ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <FormGroup>
                        <FormLabel>{'Average weight'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                disabled={true}
                                type='number'
                                placeholder='Average weight'
                                value={state.production.product_sale?.average_weight ?? 0}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <FormGroup>
                        <FormLabel>{`Product qty ${(state.production.department?.production_pack === true) ? '*' : ''}`}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled={true}
                                type='number'
                                placeholder='Product qty'
                                value={state.production.product_qty}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>

                <Grid item xs={12} sm={12} md={12} style={{ display: (state.production.department?.use_ticket) ? 'inline' : 'none' }}>
                    <FormGroup>
                        <FormLabel>{'Code - Ticket'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Code Ticket'
                                name='code_ticket'
                                value={state.production.code_ticket}
                                onChange={handleChange}
                                onKeyPress={searchTicket}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ display: (state.production.department?.use_ticket) ? 'inline' : 'none' }}>
                    <FormGroup>
                        <FormLabel>{'Ticket'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                disabled={true}
                                type='text'
                                placeholder='Ticket'
                                value={state.production.ticket?.name ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <FormGroup>
                        <FormLabel>{'Quantity'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                placeholder='Quantity'
                                name='qty'
                                value={state.production.qty}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <FormGroup>
                        <FormLabel>{'Weight'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                placeholder='Weight'
                                name='weight'
                                value={state.production.weight}
                                onChange={handleChange}
                                inputProps={{ style: { backgroundColor: (Number(state.production.weight) > Number(state.production.product_sale?.average_weight)) ? '#F8E0E0' : '#E0F8E0' } }}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormGroup>
                        <FormLabel>{'Total qty'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                placeholder='Quantity'
                                value={state.production.total_qty}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Image'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                inputRef={imgRef}
                                type='file'
                                inputProps={{ accept: 'image/*' }}
                                placeholder='Image'
                                onChange={handleImageChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>

                {
                    printState.visible ?
                        <>
                            <Grid item xs={12} sm={12} md={12} className={classes.buttonContainer}>
                                <Button
                                    variant='contained'
                                    className={classes.store}
                                    onClick={printVoucher}
                                >
                                    {'Print voucher'}
                                </Button>
                            </Grid>
                            <hr />
                            <Grid item xs={12} sm={12} md={12} className={classes.buttonContainer}>
                                <Button
                                    variant='contained'
                                    className={classes.store}
                                    onClick={printId}
                                >
                                    {'Print ID'}
                                </Button>
                            </Grid>
                            <hr />
                            <Grid item xs={12} sm={12} md={12} className={classes.buttonContainer}>
                                <Button
                                    variant='contained'
                                    className={classes.outlined}
                                    onClick={clearPrintState}
                                >
                                    {'Create new production'}
                                </Button>
                            </Grid>
                        </>
                        :
                        <Grid item xs={12} sm={12} md={12} className={classes.buttonContainer}>
                            <Button
                                variant='contained'
                                className={classes.store}
                                onClick={store}
                            >
                                {'Store'}
                            </Button>
                        </Grid>
                }
            </Grid>
        </>
    );
}

export default ProductionsMobile;