import React, { useEffect } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Grid,
  Box,
} from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["CX", "WG", "OR"];

export default function MultipleSelectCheckmarks({ updateState, onCleanCategory }) {
  const [category, setCategory] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategory(typeof value === "string" ? value.split(",") : value);
    updateState("category", value.join(","));
  };

  const cleanCategory = () => {
    setCategory([]);
  }

  useEffect(() => {
    if (onCleanCategory) {
      onCleanCategory(cleanCategory);
    }
  }, [onCleanCategory]);

  return (
    <Grid item xs={12} md={4}>
      <FormGroup>
        <FormLabel>{"Category"}</FormLabel>
        <FormControl variant="filled" component={Box} width="100%">
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={category}
            onChange={handleChange}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {names.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox checked={category.indexOf(name) > -1} color="primary"/>
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGroup>
    </Grid>
  );
}
