import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FilledInput,
  Icon,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Divider,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useLocation } from "react-router-dom";
import ViewTitle from "../../common/view-title";
import AuthContext from "../../context";
import { get } from "../../utils/api-services";
import helpers from "../../utils/helpers";
import DateRange from "../../common/date-range";
import FilterInput from "../components/FilterInput";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingBottom: 20,
  },
  tableContainer: {
    width: "100%",
    marginTop: 20,
    overflowX: "auto",
  },
  clear: {
    backgroundColor: "#A4A4A4",
    borderColor: "#A4A4A4",
    "&:hover": {
      backgroundColor: "#A4A4A4",
      borderColor: "#A4A4A4",
      boxShadow: "none",
    },
  },
  search: {
    backgroundColor: "#0d6efd",
    borderColor: "#0d6efd",
    "&:hover": {
      backgroundColor: "#0d6efd",
      borderColor: "#0d6efd",
      boxShadow: "none",
    },
  },
  acceptButton: {
    backgroundColor: "#198754",
    borderColor: "#198754",
    width: "100%",
    "&:hover": {
      backgroundColor: "#198754",
      borderColor: "#198754",
      boxShadow: "none",
    },
  },
  reportsDateTable: {
    width: "100%",
    marginBottom: 20,
    display: "flex",
    justifyContent: "center",
  },
  reportsTable: {
    padding: 10,
    tableLayout: "fixed",
  },
  dateTableCell: {
    padding: "3px",
    textAlign: "center",
    overflowWrap: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "2px",
    },
  },
  divider: {
    marginBottom: 10,
  },
}));

const MasterPrebook = () => {
  const { showToast, signOut, showLoader } = useContext(AuthContext);
  const cleanCategoryRef = useRef(null);
  const classes = useStyles();
  const { pathname } = useLocation();
  const [state, setState] = useState({
    loading: true,
    savingReports: false,
    data: [],
    pageSize: 50,
    todaysPrebook: {
      todaysTotal: 0,
      yesterdaysTotal: 0,
    },
    filter: {
      dateDateRange: `${dayjs()
        .subtract(7, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
      legacy_code: "",
      product: "",
      newDateDateRange: "",
    },
    rangeModal: {
      open: false,
      range: [
        {
          startDate: new Date(dayjs().subtract(2, "day").toDate()),
          endDate: new Date(),
          key: "date",
        },
      ],
      newDateRange: [
        {
          startDate: new Date(dayjs().subtract(2, "day").toDate()),
          endDate: new Date(),
          key: "newDate",
        },
      ],
    },
    lastDateReports: {},
    fileData: [],
    avoided: [],
    permissions: {},
  });

  const fetchDataInfo = async () => {
    try {
      const response = await get("/kometMasterPrebook/getReportDates");
      setState((prev) => ({
        ...prev,
        lastDateReports: response,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    window.document.title = "Komet Master Prebook";
    fetchDataInfo();
    validatePermissions();
  }, []);

  const validatePermissions = () => {
    const permissions = helpers.getPermissions(pathname);
    setState((prev) => ({ ...prev, permissions }));

    permissions.read_attr
      ? makeRequest()
      : setState((prev) => ({ ...prev, loading: false }));
  };

  const makeRequest = async () => {
    showLoader(true);
    const response = await get("/kometMasterPrebook/index");
    const todaysPrebooks = await get(
      "/kometMasterPrebook/getTodaysAndYesterdaysPrebookSums"
    );

    if (todaysPrebooks.status === 200) {
      setState((prev) => ({
        ...prev,
        todaysPrebook: todaysPrebooks.data,
      }));
    }

    if (response.status === 200) {
      setState((prev) => ({
        ...prev,
        loading: false,
        data: response.data,
      }));
    } else {
      console.log("[MasterPrebook] MakeRequest error", response);
      helpers.failRequest(response, showToast, signOut);
    }
    showLoader(false);
  };

  const search = async () => {
    const { filter } = state;

    const trimmedFilter = {
      ...filter,
      legacy_code: filter.legacy_code.trim().replace(/\s+/g, " "),
      product: filter.product.trim().replace(/\s+/g, " "),
    };

    showLoader(true);
    const response = await get("/kometMasterPrebook/search", trimmedFilter);
    if (response.status === 200) {
      setState((prev) => ({ ...prev, data: response.data }));
      if (!response?.data[0])
        showToast("There are no data with these filters", "warning");
    } else {
      console.log("[MasterPrebook] Search error", response);
      helpers.failRequest(response, showToast, signOut);
    }
    showLoader(false);
  };

  const reports = [
    {
      name: "Inventory Detail",
      date: state.lastDateReports.inventory?.date,
      reportStartDate: state.lastDateReports.inventory?.reportStartDate,
      reportEndDate: state.lastDateReports.inventory?.reportEndDate,
    },
    {
      name: "Prebook",
      date: state.lastDateReports.prebook?.date,
      reportStartDate: state.lastDateReports.prebook?.reportStartDate,
      reportEndDate: state.lastDateReports.prebook?.reportEndDate,
    },
    {
      name: "Sales",
      date: state.lastDateReports.sales?.date,
      reportStartDate: state.lastDateReports.sales?.reportStartDate,
      reportEndDate: state.lastDateReports.sales?.reportEndDate,
    },
    {
      name: "Shipping Inventory",
      date: state.lastDateReports.shipping?.date,
      reportStartDate: state.lastDateReports.shipping?.reportStartDate,
      reportEndDate: state.lastDateReports.shipping?.reportEndDate,
    },
  ];
  const isButtonDisabled =
    state.savingReports || reports.some((report) => report.date === "-");

  const getDate = (params) => dayjs(params.value).format("MM/DD/YYYY");
  const getMiamiTimeZoneDate = (params) => {
    return dayjs(params.value).tz("America/New_York").format("MM/DD/YYYY");
  };

  const formatNumber = (number) =>
    number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const columns = [
    {
      field: "report_date",
      headerName: "Report Date",
      flex: 0.8,
      minWidth: 150,
      valueGetter: getMiamiTimeZoneDate,
    },
    {
      field: "legacy_code",
      headerName: "Legacy Code",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "product",
      headerName: "Product",
      flex: 1,
      minWidth: 400,
    },
    {
      field: "todays_prebook",
      headerName: "Today's Prebook",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "total_cooler_in",
      headerName: "Total Cooler In",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "shipped",
      headerName: "Shipped (Invoice Boxes)",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "yet_to_shipped",
      headerName: "Yet To Shipped",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "on_hand",
      headerName: "On Hand",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "shipping_inventory",
      headerName: "Shipping Inventory",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "yet_to_produce",
      headerName: "Yet To Produce",
      flex: 1,
      minWidth: 150,
    },
    { field: "op", headerName: "OP", flex: 1, minWidth: 150 },
  ];

  const handleAutocomplete = (key, value) =>
    setState((prev) => ({
      ...prev,
      filter: { ...prev.filter, [key]: value },
    }));

  const handleClean = () => {
    setState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        product: "",
        legacy_code: "",
        dateDateRange: "",
        category: "",
        startDate: "",
        endDate: "",
      },
    }));
    if (cleanCategoryRef.current) {
      cleanCategoryRef.current();
    }
  };
  const handleDateChange = (item, key) => {
    const { startDate, endDate } = item[key];

    const adjustedStartDate = dayjs(startDate)
      .hour(0)
      .minute(1)
      .second(0)
      .millisecond(0);
    const adjustedEndDate = dayjs(endDate)
      .hour(23)
      .minute(59)
      .second(0)
      .millisecond(0);

    const dateRange = `${adjustedStartDate.format(
      "MM/DD/YYYY"
    )} - ${adjustedEndDate.format("MM/DD/YYYY")}`;

    setState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        [`${key}DateRange`]: dateRange,
        startDate: adjustedStartDate.format("YYYY-MM-DD HH:mm"),
        endDate: adjustedEndDate.format("YYYY-MM-DD HH:mm"),
      },
      rangeModal: {
        ...prev.rangeModal,
        [key === "date" ? "range" : key === "newDate" ? "newDateRange" : ""]: [
          item[key],
        ],
      },
    }));
  };

  const handleModal = (key) => {
    setState((prev) => ({
      ...prev,
      rangeModal: {
        ...prev.rangeModal,
        open: !prev.rangeModal.open,
        modalKey: key,
      },
    }));
  };
  const generateUpdatedMasterPrebook = async () => {
    try {
      setState((prev) => ({ ...prev, savingReports: true }));
      showToast("Generating Master Prebook", "warning")
      const saveRequest = await get(
        "/kometMasterPrebook/generateMasterPrebook",
        {}
      );
      setState((prev) => ({ ...prev, data: saveRequest.data }));
      setState((prev) => ({ ...prev, savingReports: false }));
      if(saveRequest.status === 200){
        showToast(saveRequest.message, "success")
      }
    } catch (error) {
      console.log(error);
      showToast("Bad request", "error")
    }
  };

  return (
    <div className={classes.root}>
      <ViewTitle sedeFlag title="Komet Master Prebook" />
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={12} md={4}>
          <FormGroup onClick={() => handleModal("date")}>
            <FormLabel>{"Date Range"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                readOnly
                type="text"
                placeholder="Select Date Range"
                value={state.filter.dateDateRange}
                endAdornment={<Icon>{"calendar_month"}</Icon>}
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <FilterInput
          label="Legacy Code"
          name="legacy_code"
          value={state.filter.legacy_code}
          handleAutocomplete={handleAutocomplete}
        />
        <MultipleSelectCheckmarks
          updateState={handleAutocomplete}
          onCleanCategory={(cleanCategory) =>
            (cleanCategoryRef.current = cleanCategory)
          }
        />
        <FilterInput
          label="Product"
          name="product"
          value={state.filter.product}
          handleAutocomplete={handleAutocomplete}
        />
        <Grid
          item
          xs={12}
          md={12}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Grid item xs={12} md={1}>
            <FormGroup>
              <FormLabel style={{ visibility: "hidden" }}>{"."}</FormLabel>
              <Button
                variant="contained"
                className={classes.clear}
                onClick={handleClean}
              >
                {"Clear"}
              </Button>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={1}>
            <FormGroup>
              <FormLabel style={{ visibility: "hidden" }}>{"."}</FormLabel>
              <Button
                variant="contained"
                className={classes.search}
                onClick={search}
              >
                {"Search"}
              </Button>
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.reportsDateTable}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={6}>
            {" "}
            <TableContainer component={Paper} className={classes.reportsTable}>
              <Table className={classes.reportsTable} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.dateTableCell} align="right">
                      Report
                    </TableCell>
                    <TableCell className={classes.dateTableCell} align="right">
                      Start Date
                    </TableCell>
                    <TableCell className={classes.dateTableCell} align="right">
                      End Date
                    </TableCell>
                    <TableCell className={classes.dateTableCell} align="right">
                      Last Update
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports.map((report, index) => (
                    <TableRow key={index}>
                      <TableCell
                        className={classes.dateTableCell}
                        align="right"
                        component="th"
                        scope="row"
                      >
                        {report.name}
                      </TableCell>
                      <TableCell
                        className={classes.dateTableCell}
                        align="right"
                        component="th"
                        scope="row"
                      >
                        {report.reportStartDate}
                      </TableCell>
                      <TableCell
                        className={classes.dateTableCell}
                        align="right"
                        component="th"
                        scope="row"
                      >
                        {report.reportEndDate}
                      </TableCell>
                      <TableCell
                        className={classes.dateTableCell}
                        align="right"
                        component="th"
                        scope="row"
                      >
                        {report.date}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {JSON.parse(localStorage.getItem("@auth_user"))
                .special_permission === true ? (
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.acceptButton}
                    onClick={generateUpdatedMasterPrebook}
                    disabled={isButtonDisabled}
                  >
                    {"Generate Master Prebook"}
                  </Button>
                </Grid>
              ) : (
                <div></div>
              )}
            </TableContainer>
          </Grid>
        </Grid>
      </div>

      <div>
        <div style={{ color: "blue", fontStyle: "italic" }}>
          <Icon style={{ verticalAlign: "middle" }}>{"arrow_upward"}</Icon>
          Yesterday's Prebook:{" "}
          {formatNumber(state.todaysPrebook.yesterdaysTotal)}
        </div>
        <div style={{ color: "green", fontWeight: "bold", fontSize: "1.2em" }}>
          <Icon style={{ verticalAlign: "middle" }}>{"arrow_downward"}</Icon>
          Today's Prebook: {formatNumber(state.todaysPrebook.todaysTotal)}
        </div>
      </div>

      <div className={classes.tableContainer}>
        <DataGrid
          rows={state.data}
          columns={columns}
          pageSize={state.pageSize}
          onPageSizeChange={(newPageSize) =>
            setState((prev) => ({
              ...prev,
              pageSize: newPageSize,
            }))
          }
          rowsPerPageOptions={[25, 50, 100]}
          density="compact"
          autoHeight
          getRowId={(row) => row.legacy_code}
        />
      </div>

      <DateRange
        open={state.rangeModal.open && state.rangeModal.modalKey === "date"}
        range={state.rangeModal.range}
        onClose={() => handleModal("date")}
        onChange={(item) => handleDateChange(item, "date")}
      />
    </div>
  );
};

export default MasterPrebook;
