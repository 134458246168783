import React from "react";
import {
  Grid,
  FormGroup,
  FormLabel,
  FormControl,
  FilledInput,
  Box,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputs: {
    backgroundColor: "red",
  },
}));

export default function FilterInput({
  label,
  name,
  value,
  handleAutocomplete,
  type = "text", 
  required = false, 
  editDialog = false,
  createBox = false
}) {
  const classes = useStyles();
  const hasError = required && !value;

  let size = (name === "product" || name === "reference" )? 6 : 4;
  if (createBox === true) size = 8

  if (editDialog) size = 4;

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (type === "number" && Number(inputValue) < 0) {
      return; 
    }
    handleAutocomplete(name, inputValue);
  };

  return (
    <Grid item xs={12} md={size} className={classes.root}>
      <FormGroup>
        <FormLabel>{label}</FormLabel>
        <FormControl
          variant="filled"
          component={Box}
          width="100%"
          error={hasError}
        >
          <FilledInput
            className={classes.inputs}
            autoComplete="off"
            type={type}
            placeholder={label}
            name={name}
            value={value}
            onChange={handleChange}
          />
        </FormControl>
      </FormGroup>
    </Grid>
  );
}
