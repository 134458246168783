import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ReactExport from 'react-data-export';
import dayjs from 'dayjs';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(() => ({
    search: {
        marginRight: 15,
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    },
    download: {
        marginRight: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none'
        }
    }
}));

const ProductSalesToDownload = ({ data, onClick, onReset }) => {

    const classes = useStyles();

    return (
        <div>
            {
                data?.recipes ?
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-bom-cost-report`}
                        element={<Button variant='contained' className={classes.download} onClick={onReset}>{'Download excel'}</Button>}
                    >
                        <ExcelSheet data={data.recipes} name='Recipes'>
                            <ExcelColumn label='Recipe ID' value='idproduct_sale' />
                            <ExcelColumn label='Code' value='code' />
                            <ExcelColumn label='Description' value='description' />
                            <ExcelColumn label='Sede code' value='sedes.sede.code' />
                            <ExcelColumn label='Sede name' value='sedes.sede.name' />
                            <ExcelColumn label='Pack qty' value='pack_qty' />
                            <ExcelColumn label='Supplier (Shed)' value='supplier_shed' />
                            <ExcelColumn label='Department' value='department.name' />
                            <ExcelColumn label='Final cost per product' value='sedes.final_cost_product' />
                            <ExcelColumn label='Final cost per pack' value='sedes.final_cost_pack' />
                            <ExcelColumn label='Piece rate' value='sedes.piece_rate' />
                            <ExcelColumn label='Units hour' value='sedes.units_hour' />
                            <ExcelColumn label='Average weight' value='average_weight' />
                            <ExcelColumn label='Last update' value='last_update' />
                        </ExcelSheet>

                        <ExcelSheet data={data.raws} name='Raws'>
                            <ExcelColumn label='Recipe ID' value='idproduct_sale' />
                            <ExcelColumn label='Recipe code' value='header.code' />
                            <ExcelColumn label='Recipe description' value='header.description' />
                            <ExcelColumn label='Product code' value='product.code' />
                            <ExcelColumn label='Product name' value='product.name' />
                            <ExcelColumn label='Unit qty' value='unit_qty' />
                            <ExcelColumn label='Unit cost' value='unit_cost' />
                            <ExcelColumn label='Shrink unit' value='shrink_unit' />
                            <ExcelColumn label='Pack qty' value='pack_qty' />
                            <ExcelColumn label='Pack cost' value='pack_cost' />
                            <ExcelColumn label='Shrink pack' value='shrink_pack' />
                            <ExcelColumn label='Total unit cost' value='total_unit_cost' />
                            <ExcelColumn label='Total pack cost' value='total_pack_cost' />
                            <ExcelColumn label='Subassembly from (ID)' value='subassembly.id' />
                            <ExcelColumn label='Subassembly from (Code)' value='subassembly.code' />
                        </ExcelSheet>

                        <ExcelSheet data={data.materials} name='Materials'>
                            <ExcelColumn label='Recipe ID' value='idproduct_sale' />
                            <ExcelColumn label='Recipe code' value='header.code' />
                            <ExcelColumn label='Recipe description' value='header.description' />
                            <ExcelColumn label='Product code' value='product.code' />
                            <ExcelColumn label='Product name' value='product.name' />
                            <ExcelColumn label='Unit cost' value='unit_cost' />
                            <ExcelColumn label='Unit used (material)' value='unit_used_material' />
                            <ExcelColumn label='Unit used cost' value='unit_used_cost' />
                            <ExcelColumn label='Shrink unit' value='shrink_unit' />
                            <ExcelColumn label='Pack qty' value='pack_qty' />
                            <ExcelColumn label='Pack cost' value='pack_cost' />
                            <ExcelColumn label='Shrink pack' value='shrink_pack' />
                            <ExcelColumn label='Total unit cost' value='total_unit_cost' />
                            <ExcelColumn label='Total pack cost' value='total_pack_cost' />
                            <ExcelColumn label='Subassembly from (ID)' value='subassembly.id' />
                            <ExcelColumn label='Subassembly from (Code)' value='subassembly.code' />
                        </ExcelSheet>

                        <ExcelSheet data={data.labors} name='Labors'>
                            <ExcelColumn label='Recipe ID' value='idproduct_sale' />
                            <ExcelColumn label='Recipe code' value='header.code' />
                            <ExcelColumn label='Recipe description' value='header.description' />
                            <ExcelColumn label='Unit qty' value='unit_qty' />
                            <ExcelColumn label='Pack qty' value='pack_qty' />
                            <ExcelColumn label='Piece rate' value='piece_rate' />
                            <ExcelColumn label='Edited manualy' value='edited' />
                            <ExcelColumn label='PS - Supervision (unit)' value='supervision_unit' />
                            <ExcelColumn label='I - IRS (unit)' value='irs_unit' />
                            <ExcelColumn label='PW - Support & Waste (unit)' value='support_waste_unit' />
                            <ExcelColumn label='H - H2B Expense (unit)' value='h2b_expense_unit' />
                            <ExcelColumn label='PS - Supervision (pack)' value='supervision_pack' />
                            <ExcelColumn label='I - IRS (pack)' value='irs_pack' />
                            <ExcelColumn label='PW - Support & Waste (pack)' value='support_waste_pack' />
                            <ExcelColumn label='H - H2B Expense (pack)' value='h2b_expense_pack' />
                            <ExcelColumn label='Subassembly from (ID)' value='subassembly.id' />
                            <ExcelColumn label='Subassembly from (Code)' value='subassembly.code' />
                        </ExcelSheet>
                    </ExcelFile>
                    :
                    <Button variant='contained' className={classes.search} onClick={onClick}>{'Search to download'}</Button>

            }
        </div >
    );
}

export default ProductSalesToDownload;