import isoWeek from 'dayjs/plugin/isoWeek';
import dayjs from 'dayjs';

dayjs.extend(isoWeek);

class Helpers {
    failRequest(response, showToast, signOut = false) {
        if (response.authorization === false) {
            showToast(response.message, 'warning');
            signOut && signOut();
        }
        if (response.status === 400) {
            showToast(response?.message ?? response.error, 'error');
        }
    }

    getInitials(user) {
        const names = user.names?.split(' ');
        const surnames = user.surnames?.split(' ');
        const array = [...names, ...surnames];

        switch (array.length) {
            case 3:
                return array[0]?.charAt(0) + array[1]?.charAt(0) + array[2]?.charAt(0);

            case 4:
                return array[0]?.charAt(0) + array[2]?.charAt(0);

            default:
                return array[0]?.charAt(0) + array[1]?.charAt(0);
        }
    }

    isAdmin() {
        const user = JSON.parse(localStorage.getItem('@auth_user'));

        return user.idrole === 1;
    }

    getPermissions(path = '') {
        const user = JSON.parse(localStorage.getItem('@auth_user'));

        let permissions = {
            create_attr: false,
            read_attr: false,
            update_attr: false,
            delete_attr: false,
        }
        if (user.idrole !== 1) {
            user.role?.options?.map(value => {
                if (path.includes(value?.option?.path)) {
                    permissions = value;
                }

                return value;
            });
        } else {
            permissions = {
                create_attr: true,
                read_attr: true,
                update_attr: true,
                delete_attr: true,
            }
        }

        return permissions;
    }

    getAuthUser() {
        const user = JSON.parse(localStorage.getItem('@auth_user'));
        const sede = JSON.parse(localStorage.getItem('@sede'));
        const initials = this.getInitials(user);

        return { ...user, initials, sede };
    }

    hexToRGBA(hex, a = 1) {
        hex = hex.replace(/^#/, '');

        const r = parseInt(hex.slice(0, 2), 16);
        const g = parseInt(hex.slice(2, 4), 16);
        const b = parseInt(hex.slice(4, 6), 16);

        return `rgba(${r}, ${g}, ${b}, ${a})`;
    }

    hasSpecialPermission() {
        const user = JSON.parse(localStorage.getItem('@auth_user'));

        return (user.idrole === 1) ? true : Boolean(user.special_permission);
    }

    getWeeksOfDates(start, end) {
        const d1 = dayjs(start).format('YYYY-MM-DD'), d2 = dayjs(end).add(1, 'day').format('YYYY-MM-DD');

        let date = d1;
        const days = [];
        const weeks = [];
        while (date != d2) {
            days.push(date);

            let week = `${dayjs(date).isoWeekYear()}-${dayjs(date).isoWeek()}`;
            if (!weeks.includes(week)) {
                weeks.push(week);
            }

            date = dayjs(date).add(1, 'day').format('YYYY-MM-DD');
        }

        return { days, weeks };
    }
}

export default new Helpers();