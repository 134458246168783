import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, FilledInput, Typography, Button, FormGroup, FormLabel, FormControl, Box, Grid } from '@material-ui/core';

import themeColors from '../assets/colors';
import { post } from '../utils/api-services';
import helpers from '../utils/helpers';
import AuthContext from '../context';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        width: '80%',
        borderRadius: 10
    },
    store: {
        marginLeft: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    bottom: {
        display: 'flex',
        alignSelf: 'flex-end',
    },
    image: {
        width: '50%',
        maxHeight: 400,
        maxWidth: 400,
        objectFit: 'contain'
    }
}));

const UploadImageModal = ({ open, onClose, url, name }) => {

    const classes = useStyles();
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const [image, setImage] = useState(null);

    const handleChange = ({ target }) => {
        const file = target?.files[0] ?? null;
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                setImage(reader.result);
            }
        } else {
            setImage(file);
        }
    }

    const store = async () => {
        showLoader(true);

        const response = await post(url, { image });
        if (response.status === 200) {
            showToast(response.message);
            closeModal(response.image);
        } else {
            console.log('[UploadImage] store error', response);
            helpers.failRequest(response, showToast, signOut);
        }

        showLoader(false);
    }

    const closeModal = (image = null) => {
        setImage(null);
        onClose(image)
    }

    return (
        <Modal
            open={open}
            onClose={() => closeModal()}
            className={classes.root}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{'Upload image'}</Typography>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>{'Code'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    type='text'
                                    placeholder='Code'
                                    name='code'
                                    value={`${name}`}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>{'Image'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    type='file'
                                    inputProps={{ accept: 'image/*' }}
                                    placeholder='Image'
                                    id='image'
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    {
                        image &&
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <img src={image} className={classes.image} alt='image to upload' />
                            </FormGroup>
                        </Grid>
                    }
                </Grid>
                <div className={classes.bottom}>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => closeModal()}
                    >
                        {'Cancel'}
                    </Button>
                    <Button
                        variant='contained'
                        className={classes.store}
                        onClick={store}
                    >
                        {'Store'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default UploadImageModal;