import React from 'react';
import { Grid, FormGroup, FormLabel, TextField } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

function CustomAutocomplete({
  label,
  field,
  value,
  options,
  optionKey,
  onChange,
  placeholder,
  error = false
}) {
  const size = field === "product" ? 8 : 4;

  // Filtramos los valores únicos de `options` usando `optionKey` como criterio
  const uniqueOptions = options.filter(
    (option, index, self) =>
      index === self.findIndex((o) => o[optionKey] === option[optionKey])
  );

  return (
    <Grid item xs={12} lg={size}>
      <FormGroup>
        <FormLabel>{label}</FormLabel>
        <Autocomplete
          options={uniqueOptions}
          value={value || ""}
          getOptionLabel={(option) =>
            typeof option === 'number' ? option.toString() : 
            typeof option === 'string' ? option : 
            option?.[optionKey]?.toString() ?? ""
          }
          isOptionEqualToValue={(option, value) =>
            Number(option?.[optionKey]) === Number(value) || 
            Number(option) === Number(value)
          }
          onChange={(e, selectedValue) => {
            let newValue;
            if (typeof selectedValue === 'string' || typeof selectedValue === 'number') {
              newValue = selectedValue;
            } else if (selectedValue && selectedValue[optionKey] !== undefined) {
              newValue = selectedValue[optionKey];
            } else {
              newValue = "";
            }
            if (field === "legacy_code") {
              const index = uniqueOptions.findIndex((option) => Number(option.legacy_code) === Number(newValue));
              onChange(field, newValue, index);
            } else {
              onChange(field, newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              error={error}
            />
          )}
          disableClearable
          freeSolo
        />
      </FormGroup>
    </Grid>
  );
}



export default CustomAutocomplete;
