import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FilledInput,
  Icon,
  Divider,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useLocation } from "react-router-dom";
import ViewTitle from "../../common/view-title";
import AuthContext from "../../context";
import { get } from "../../utils/api-services";
import helpers from "../../utils/helpers";
import DateRange from "../../common/date-range";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import FilterInput from "../components/FilterInput";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingBottom: 20,
  },
  tableContainer: {
    width: "100%",
    marginTop: 20,
  },
  buttonsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  clear: {
    backgroundColor: "#A4A4A4",
    borderColor: "#A4A4A4",
    "&:hover": {
      backgroundColor: "#A4A4A4",
      borderColor: "#A4A4A4",
      boxShadow: "none",
    },
  },
  search: {
    backgroundColor: "#0d6efd",
    borderColor: "#0d6efd",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#0d6efd",
      borderColor: "#0d6efd",
      boxShadow: "none",
    },
  },
  acceptButton: {
    backgroundColor: "#198754",
    borderColor: "#198754",
    marginTop: 20,
    marginBottom: 20,
    width: "50%",
    "&:hover": {
      backgroundColor: "#198754",
      borderColor: "#198754",
      boxShadow: "none",
    },
  },
  deleteReportButton: {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    marginTop: 20,
    marginLeft: 10,
    width: "20%",
    "&:hover": {
      backgroundColor: "#c82333",
      borderColor: "#bd2130",
      boxShadow: "none",
    },
  },
  requiredValue: {
    fontWeight: "600",
    color: "rgba(255,0,0,1)",
  },
  divider: {
    marginBottom: 10,
  },
}));

const Prebook = () => {
  const { showToast, signOut, showLoader } = useContext(AuthContext);
  const cleanCategoryRef = useRef(null);
  const classes = useStyles();
  const { pathname } = useLocation();
  const [state, setState] = useState({
    loading: true,
    data: [],
    savingReports: false,
    pageSize: 50,
    filter: {
      truckDateRange: `${dayjs()
        .subtract(7, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
      legacy_code: "",
      product: "",
      prebook: "",
      category: "",
    },
    rangeModal: {
      open: false,
      range: [
        {
          startDate: new Date(dayjs().subtract(2, "day").toDate()),
          endDate: new Date(),
          key: "truck",
        },
      ],
    },
    requestRangeModal: {
      open: false,
      dateRange: `${dayjs()
        .subtract(4, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
      startDate: `${dayjs().subtract(4, "day").format("YYYY-MM-DD")}`,
      endDate: `${dayjs().format("YYYY-MM-DD")}`,
      range: [
        {
          startDate: new Date(dayjs().subtract(4, "day").toDate()),
          endDate: new Date(dayjs().toDate()),
          key: "main",
        },
      ],
    },
    fileData: [],
    avoided: [],
    permissions: {},
  });

  useEffect(() => {
    window.document.title = "Komet Prebook";

    validatePermissions();
  }, []);

  const validatePermissions = () => {
    const permissions = helpers.getPermissions(pathname);
    setState((prev) => ({ ...prev, permissions }));

    permissions.read_attr
      ? makeRequest()
      : setState((prev) => ({ ...prev, loading: false }));
  };

  const makeRequest = async () => {
    showLoader(true);
    const response = await get("/kometPrebook/index", state.filter);
    if (response.status === 200) {
      setState((prev) => ({ ...prev, loading: false, data: response.data }));
    } else {
      console.log("[PrebookReport] makeRequest error", response);
      helpers.failRequest(response, showToast, signOut);
    }
    showLoader(false);
  };

  const getDate = (params) => dayjs(params.value).format("MM/DD/YYYY");
  const getMiamiTimeZoneDate = (params) => {
    return dayjs(params.value).tz("America/New_York").format("MM/DD/YYYY");
  };

  const columns = [
    {
      field: "idkomet_prebook_log",
      headerName: "Id",
      flex: 0.8,
      minWidth: 100,
    },
    {
      field: "report_date",
      headerName: "Report Date",
      flex: 1,
      minWidth: 150,
      valueGetter: getMiamiTimeZoneDate,
    },
    {
      field: "komet_category",
      headerName: "Category",
      flex: 1,
      minWidth: 200,
    },
    { field: "legacy_code", headerName: "Legacy Code", flex: 1, minWidth: 150 },
    { field: "product", headerName: "Product", flex: 1, minWidth: 350 },
    { field: "prebook", headerName: "Prebook", flex: 1, minWidth: 150 },
    {
      field: "wk_truck_day",
      headerName: "WK Truck Date",
      flex: 1,
      minWidth: 150,
    },
    { field: "so", headerName: "SO", flex: 1, minWidth: 150 },
    {
      field: "customer_po",
      headerName: "Customer PO #",
      flex: 1,
      minWidth: 250,
    },
    { field: "customer", headerName: "Customer", flex: 1, minWidth: 175 },
    {
      field: "sales_person",
      headerName: "Sales Person",
      flex: 1,
      minWidth: 150,
    },
    { field: "carrier", headerName: "Carrier", flex: 1, minWidth: 200 },
    {
      field: "truck_date",
      headerName: "Truck Date",
      flex: 1,
      minWidth: 150,
      valueGetter: getDate,
    },
    {
      field: "presold_quantity",
      headerName: "Presold Quantity",
      flex: 1,
      minWidth: 200,
    },
    { field: "type", headerName: "Type", flex: 1, minWidth: 150 },
    { field: "unit", headerName: "Unit", flex: 1, minWidth: 150 },
    { field: "unit_type", headerName: "Unit Type", flex: 1, minWidth: 150 },
    { field: "bunches", headerName: "Bunches", flex: 1, minWidth: 150 },
    { field: "stems_bunch", headerName: "Stems/Bunch", flex: 1, minWidth: 150 },
    { field: "total_units", headerName: "Total Units", flex: 1, minWidth: 150 },
    { field: "cost", headerName: "Cost", flex: 1, minWidth: 150 },
    { field: "total_cost", headerName: "Total Cost", flex: 1, minWidth: 150 },
    { field: "price", headerName: "Price", flex: 1, minWidth: 150 },
    { field: "total_price", headerName: "Total Price", flex: 1, minWidth: 150 },
    { field: "mark_code", headerName: "Mark Code", flex: 1, minWidth: 150 },
    { field: "po", headerName: "PO", flex: 1, minWidth: 150 },
    {
      field: "purchased_boxes",
      headerName: "Purchased Boxes",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "purchased_units",
      headerName: "Purchased Units",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "billed_boxes",
      headerName: "Billed Boxes",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "billed_units",
      headerName: "Billed Units",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "mark_as_completed",
      headerName: "Marked as Completed",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "prebook_sumary",
      headerName: "Prebook Summary",
      flex: 1,
      minWidth: 200,
    },
  ];

  const handleAutocomplete = (key, value) =>
    setState((prev) => ({
      ...prev,
      filter: { ...prev.filter, [key]: value },
    }));

  const handleClean = () => {
    setState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        product: "",
        legacy_code: "",
        prebook: "",
        truckDateRange: "",
        category: "",
        truck_start_date: "",
        truck_end_date: "",
      },
    }));
    if (cleanCategoryRef.current) {
      cleanCategoryRef.current();
    }
  };

  const handleChange = ({ target }) =>
    setState((prev) => ({
      ...prev,
      department: { ...prev.department, [target.name]: target.value },
    }));

  const search = async () => {
    const { filter } = state;

    const hasInvalidCharacter = Object.values(filter).some((value) =>
      value.includes("#")
    );
    if (hasInvalidCharacter) {
      showToast("Filters cannot contain the '#' character", "error");
      return;
    }

    const trimmedFilter = {
      ...filter,
      legacy_code: filter.legacy_code.trim().replace(/\s+/g, " "),
      product: filter.product.trim().replace(/\s+/g, " "),
      supplier_cx: filter.prebook.trim().replace(/\s+/g, " "),
    };

    showLoader(true);
    const response = await get("/kometPrebook/search", trimmedFilter);
    if (response.status === 200) {
      setState((prev) => ({ ...prev, data: response.data }));
      if (!response?.data[0])
        showToast("There are no data with these filters", "warning");
    } else {
      console.log("[Prebook] search error", response);
      helpers.failRequest(response, showToast, signOut);
    }
    showLoader(false);
  };

  const handleDateChange = (item, key) => {
    const { startDate, endDate } = item[key];
    const dateRange = `${dayjs(startDate.toISOString()).format(
      "MM/DD/YYYY"
    )} - ${dayjs(endDate.toISOString()).format("MM/DD/YYYY")}`;
    setState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        [`${key}DateRange`]: dateRange,
        [`${key}_start_date`]: dayjs(startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        [`${key}_end_date`]: dayjs(endDate.toISOString()).format("YYYY-MM-DD"),
      },
      rangeModal: {
        ...prev.rangeModal,
        [key === "truck" ? "range" : "reportRange"]: [item[key]],
      },
    }));
  };

  const handleModal = (key) => {
    setState((prev) => ({
      ...prev,
      rangeModal: {
        ...prev.rangeModal,
        open: !prev.rangeModal.open,
        modalKey: key,
      },
    }));
  };

  const handleRequestModal = () => {
    setState((prev) => {
      const isClosing = prev.requestRangeModal.open;
      return {
        ...prev,
        requestRangeModal: {
          ...prev.requestRangeModal,
          open: !prev.requestRangeModal.open,
          ...(isClosing && {
            range: [
              {
                startDate: new Date(dayjs().subtract(4, "day").toDate()),
                endDate: new Date(dayjs().toDate()),
                key: "main",
              },
            ],
            startDate: `${dayjs().subtract(4, "day").format("YYYY-MM-DD")}`,
            endDate: `${dayjs().format("YYYY-MM-DD")}`,
            minDate: null,
            maxDate: null,
          }),
        },
      };
    });
  };

  const handleRequestDateChange = (item) => {
    const { startDate, endDate } = item.main;
    const minDate = dayjs(startDate).subtract(365, "day").toDate();
    const maxDate = dayjs(startDate).add(365, "day").toDate();
    const dateRange = `${dayjs(startDate.toISOString()).format(
      "MM/DD/YYYY"
    )} - ${dayjs(endDate.toISOString()).format("MM/DD/YYYY")}`;
    setState((prev) => ({
      ...prev,
      requestRangeModal: {
        open: true,
        range: [item.main],
        dateRange: dateRange,
        startDate: `${dayjs(startDate.toISOString()).format("YYYY-MM-DD")}`,
        endDate: `${dayjs(endDate.toISOString()).format("YYYY-MM-DD")}`,
        minDate,
        maxDate,
      },
    }));
  };

  const addRequest = async () => {
    showToast("Your prebook report is being processed.", "warning");
    setState((prev) => ({
      ...prev,
      savingReports: true,
    }));
    const saveRequest = await get("/kometPrebook/store", {
      startDate: state.requestRangeModal.startDate,
      endDate: state.requestRangeModal.endDate,
    });

    if (saveRequest.status === 200) {
      setState((prev) => ({
        ...prev,
        data: saveRequest.data,
        savingReports: false,
      }));
      if (saveRequest.data.length === 0) {
        showToast(saveRequest.message, "error");
      } else {
        showToast("Your prebook report is ready", "success");
      }
    } else {
      console.log("[Request] Request error", saveRequest);
      showToast(saveRequest.message, saveRequest);
    }
  };

  return (
    <div className={classes.root}>
      <ViewTitle sedeFlag title="Komet Prebook" />

      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={12} md={4}>
          <FormGroup onClick={handleRequestModal}>
            <FormLabel>{"Generate Prebook Report"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                readOnly
                type="text"
                placeholder="Generate Prebook Report"
                value={state.requestRangeModal.dateRange}
                disabled={state.savingReports}
                endAdornment={<Icon>{"calendar_month"}</Icon>}
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4} className={classes.buttonRequestContainer}>
          <Button
            variant="contained"
            className={classes.acceptButton}
            onClick={addRequest}
            disabled={state.savingReports}
          >
            {"Generate Report"}
          </Button>
        </Grid>
      </Grid>
      <DateRange
        open={state.requestRangeModal.open}
        range={state.requestRangeModal.range}
        minDate={state.requestRangeModal.minDate}
        maxDate={state.requestRangeModal.maxDate}
        onClose={handleRequestModal}
        onChange={handleRequestDateChange}
      />
      <Divider className={classes.divider} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormGroup onClick={() => handleModal("truck")}>
            <FormLabel>{"Truck Date Range"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                readOnly
                type="text"
                placeholder="Select Truck Date Range"
                value={state.filter.truckDateRange}
                endAdornment={<Icon>{"calendar_month"}</Icon>}
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <FilterInput
          label="Legacy Code"
          name="legacy_code"
          value={state.filter.legacy_code}
          handleAutocomplete={handleAutocomplete}
        />
        <FilterInput
          label="Prebook"
          name="prebook"
          value={state.filter.prebook}
          handleAutocomplete={handleAutocomplete}
        />
        <FilterInput
          label="Product"
          name="product"
          value={state.filter.product}
          handleAutocomplete={handleAutocomplete}
        />
        <MultipleSelectCheckmarks
          updateState={handleAutocomplete}
          onCleanCategory={(cleanCategory) =>
            (cleanCategoryRef.current = cleanCategory)
          }
        />
      </Grid>

      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          className={classes.clear}
          onClick={handleClean}
        >
          {"Clear"}
        </Button>
        <Button variant="contained" className={classes.search} onClick={search}>
          {"Search"}
        </Button>
      </div>

      <div className={classes.tableContainer}>
        <DataGrid
          rows={state.data}
          columns={columns}
          pageSize={state.pageSize}
          onPageSizeChange={(newPageSize) =>
            setState((prev) => ({
              ...prev,
              pageSize: newPageSize,
            }))
          }
          rowsPerPageOptions={[25, 50, 100]}
          density="compact"
          autoHeight
          getRowId={(row) => row.idkomet_prebook_log}
        />
      </div>

      <DateRange
        open={state.rangeModal.open && state.rangeModal.modalKey === "truck"}
        range={state.rangeModal.range}
        onClose={() => handleModal("truck")}
        onChange={(item) => handleDateChange(item, "truck")}
      />
    </div>
  );
};

export default Prebook;
